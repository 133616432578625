import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Statistic,
  Progress,
  Table,
  Space,
  Button,
  Collapse,
  Modal,
  Input,
  InputNumber,
  Form,
  Switch,
  message,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import { firestore } from "../../../firebase";

const EventDetails = ({ location, addToGuestList, updateGuestlist }) => {
  const [visible, setVisible] = useState(false);
  const eventId = location.state.eventId;
  const upcoming = location.state.upcoming; //this upcoming variable checks if it is a past event or a current event
  const [filteredInfo, setFilteredInfo] = useState({});
  const [form] = Form.useForm();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [arrivedGuests, setArrivedGuests] = useState(0);
  const [guestlist, setGuestlist] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    //retrieves info for a particular event
    const unsubscribe = firestore
      .collection("events")
      .doc(eventId)
      .onSnapshot(
        (doc) => {
          setEvent(doc.data());
          setLoading(false);
          setGuestlist(doc.data().guestlist);
          setArrivedGuests(numGuestsArrived(doc.data().guestlist));
        },
        function (error) {
          console.log("Error getting document:", error);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [eventId]);
  const numGuestsArrived = (guestlist) => {
    //adds the party size of each guest on the guestlist that has arrived
    let arrived = 0;
    guestlist.forEach((guest) => {
      if (guest.arrived === true) {
        arrived += guest.size;
      }
    });
    return arrived;
  };
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
  };

  const handleAdd = (guest) => {
    //add guests to the guestlist
    if (upcoming === false) {
      message.error("Can't edit past events");
      return;
    }
    const newNumGuests = event.numGuests + guest.size;
    if (newNumGuests > event.maxGuests) {
      message.error(
        `The max capacity of the guestlist is ${event.maxGuests}. Edit the guestlist size to increase the max capacity`
      );
      return;
    }
    const guestData = {
      ...guest,
      arrived: false,
    };
    const data = {
      guestlist: [...event.guestlist, guestData],
      numGuests: newNumGuests,
    };
    addToGuestList(data, eventId, guest.size);
    form.resetFields();
    setVisible(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Party Size",
      dataIndex: "size",
      key: "size",
      ellipsis: true,
    },
    {
      title: "Arrived",
      dataIndex: "arrived",
      key: "arrived",
      render: (arrived, record) => (
        <Switch
          className="switch"
          checked={arrived}
          onChange={async () => {
            if (upcoming === false) {
              message.error("Can't edit past events");
              return;
            }
            const newGuestlist = event.guestlist.map((party) => {
              if (party.name === record.name && party.size === record.size) {
                party.arrived = !party.arrived;
              }
              return party;
            });
            let newNumGuests = numGuestsArrived(newGuestlist);
            //make the arrived guests progress circle slowly increase or decrease to make it look animated
            if (arrivedGuests < newNumGuests) {
              for (let i = arrivedGuests; i < newNumGuests; i++) {
                setArrivedGuests(i);
                await new Promise((r) => setTimeout(r, 25));
              }
            } else {
              for (let i = arrivedGuests; i > newNumGuests; i--) {
                setArrivedGuests(i);
                await new Promise((r) => setTimeout(r, 25));
              }
            }

            updateGuestlist(newGuestlist, eventId);
          }}
        />
      ),
      filters: [
        { text: "Has Arrived", value: "True" },
        { text: "Not Arrived Yet", value: "False" },
      ],
      filteredValue: filteredInfo.arrived || null,
      onFilter: (value, record) => record.arrived === value,
    },
  ];
  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <MainContainer>
        <Col lg={24}>
          <Collapse
            style={{ backgroundColor: "transparent", borderWidth: "0px" }}
            ghost
          >
            <Collapse.Panel
              id="event-collapse"
              style={{
                backgroundColor: "transparent",
                marginBottom: "15px",
                borderWidth: "0px",
              }}
              header={<h1 style={{ fontSize: "25px" }}>{event.name}</h1>}
              key="1"
            >
              <p>{event.description}</p>
            </Collapse.Panel>
          </Collapse>
          <Row
            gutter={[
              { xs: 8, sm: 8, md: 8, lg: 16 },
              { xs: 8, sm: 8, md: 8, lg: 16 },
            ]}
          >
            <Col xs={24} md={24} lg={8}>
              <Row
                style={{ height: "100%" }}
                gutter={[
                  { xs: 4, sm: 6, md: 8, lg: 10 },
                  { xs: 6, sm: 6, md: 8, lg: 10 },
                ]}
              >
                <Col span={24}>
                  <Card
                    className="elevated"
                    style={{ height: "100%" }}
                    bodyStyle={{
                      height: "100%",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                    }}
                  >
                    <Statistic
                      title="Date"
                      value={moment(event.date).format("dddd DD MMMM YYYY")}
                      valueStyle={{ color: "#a0905d", fontSize: "16px" }}
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className="elevated"
                    style={{ height: "100%" }}
                    bodyStyle={{
                      height: "100%",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                    }}
                  >
                    <Statistic
                      title="Cover Price"
                      value={event.coverPrice}
                      valueStyle={{ color: "#a0905d", fontSize: "16px" }}
                      suffix=" $"
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className="elevated"
                    style={{ height: "100%" }}
                    bodyStyle={{
                      height: "100%",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                    }}
                  >
                    <Statistic
                      title="Cut Off Time"
                      value={event.cutOffTime}
                      valueStyle={{ color: "#a0905d", fontSize: "16px" }}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Card
                className="elevated"
                bodyStyle={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Progress
                  type="circle"
                  percent={Math.round(
                    (event.numGuests / event.maxGuests) * 100
                  )}
                  strokeColor="#a0905d"
                />
                <Statistic
                  title="GuestList Capacity"
                  value={event.numGuests}
                  suffix={`/ ${event.maxGuests}`}
                  style={{ paddingLeft: "20px" }}
                />
              </Card>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Card
                className="elevated"
                bodyStyle={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Progress
                  type="circle"
                  percent={Math.round((arrivedGuests / event.numGuests) * 100)}
                  strokeColor="#a0905d"
                />
                <Statistic
                  title="Arrived Guests"
                  value={arrivedGuests}
                  suffix={`/ ${event.numGuests}`}
                  style={{ paddingLeft: "20px" }}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Table
                className="elevated"
                pagination={false}
                columns={columns}
                dataSource={guestlist}
                onChange={handleChange}
                style={{ marginTop: "10px" }}
                title={() => (
                  <TableHeader>
                    <h1 style={{ fontSize: "22px" }}>Guestlist</h1>
                    <TableButtons
                      direction={
                        window.innerWidth < 700 ? "vertical" : "horizontal"
                      }
                    >
                      <Modal
                        title="Add to Guestlist"
                        visible={visible}
                        onOk={() => form.submit()}
                        onCancel={() => setVisible(false)}
                        footer={[
                          <Button key="back" onClick={() => setVisible(false)}>
                            Cancel
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={() => form.submit()}
                          >
                            Submit
                          </Button>,
                        ]}
                      >
                        <Form
                          layout="vertical"
                          form={form}
                          onFinish={handleAdd}
                        >
                          <Form.Item name="name" label="Reservation Name">
                            <Input className="elevated" />
                          </Form.Item>
                          <Form.Item
                            name="size"
                            label="Party Size"
                            rules={[{ type: "number", min: 0, max: 99 }]}
                          >
                            <InputNumber className="elevated" />
                          </Form.Item>
                        </Form>
                      </Modal>
                      <Button block onClick={() => setVisible(true)}>
                        Manually Add To Guestlist
                      </Button>
                      <Button block onClick={() => console.log("clicked")}>
                        Print Guestlist
                      </Button>
                      <Button block onClick={() => console.log("clicked")}>
                        Export
                      </Button>
                      <Input
                        placeholder="Search by name or date"
                        value={search}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSearch(value);
                          setGuestlist(() => {
                            return event.guestlist.filter(
                              (guest) =>
                                guest.name
                                  .toLowerCase()
                                  .replace(/ +/g, "")
                                  .indexOf(
                                    value.toLowerCase().replace(/ +/g, "")
                                  ) !== -1
                            );
                          });
                        }}
                        style={{
                          flex: 1,
                          borderColor: "#a0905d",
                          color: "#a0905d",
                          height: "32px",
                        }}
                      />
                    </TableButtons>
                  </TableHeader>
                )}
              />
            </Col>
          </Row>
        </Col>
      </MainContainer>
    );
  }
};

const MainContainer = styled(Row)``;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const TableButtons = styled(Space)`
  margin-bottom: 16px;
  @media only screen and (max-width: 600px) {
    display: inline-block;
  }
`;

export default EventDetails;
