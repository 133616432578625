import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import VenueContext from "../../contexts/VenueContext";

const VenueProvider = ({ children }) => {
  // need to manually set up the venue id as we have not set up the sign up process
  const venueId = "tXQ5VKi0QI78TdH1PYBq";
  const [venue, setVenue] = useState({});
  useEffect(() => {
    //retrieve venue information
    const unsubscribe = firestore
      .collection("venues")
      .doc(venueId)
      .onSnapshot(
        (doc) => {
          console.log("Fetching from FIREBASE");
          if (doc.exists) {
            setVenue(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        },
        function (error) {
          console.log("Error getting document:", error);
        }
      );
    return () => {
      unsubscribe();
    };
  }, []);
  return <VenueContext.Provider value={{ venue, venueId }}>{children}</VenueContext.Provider>;
};

export default VenueProvider;
