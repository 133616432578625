import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Switch,
  Select,
  message,
  Progress,
  Upload,
  TimePicker,
} from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { storage } from "../../../firebase";
import VenueContext from "../../../contexts/VenueContext";
import moment from "moment";

const FormContainer = styled.div`
  label {
    color: black;
  }
`;

const CreateEventForm = (props) => {
  const { venueId } = useContext(VenueContext);
  const [hasDuration, setHasDuration] = useState(false);
  const [fileList, setFilelist] = useState([]);
  const [progress, setProgress] = useState(null);
  const history = useHistory();

  const customUpload = ({ onError, file }) => {
    //Uploads images to Firebase
    const metadata = {
      contentType: "image/jpeg",
    };
    const storageRef = storage.ref();
    const imageName = file.name.split(".")[0] + file.uid;
    console.log(imageName);
    const uploadTask = storageRef
      .child(`images/${venueId}/events/${imageName}.jpg`)
      .put(file, metadata);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var downloadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + downloadProgress + "% done");
        setProgress(Math.round(downloadProgress));
      },
      function (error) {
        // Handle unsuccessful uploads
        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        setProgress(null);
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          const img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: downloadURL,
            thumbUrl: downloadURL,
          };
          console.log(img);
          setFilelist([...fileList, img]);
        });
      }
    );
  };
  const beforeUpload = (file) => {
    if (fileList.length === 1) {
      message.error("You can only upload one image for an event");
      return false;
    }
    //checks to see if it is a valid image file
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("You can only upload an image file!");
    }
    return isImage;
  };

  const onRemove = async (file) => {
    try {
      props.deletePhotos(file);
      setFilelist([]);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = (fieldsValue) => {
    //Add image to the event data
    const data = {
      ...fieldsValue,
      image: fileList[0],
    };
    console.log(data);
    if (hasDuration && fieldsValue.duration === 0) {
      message.error("You must enter a duration for weekly events");
      return;
    }
    if (!data.image) {
      message.error("An image is required");
      return;
    }
    props.handleCreate(data);
    history.push("/owners/portal/events/active");
  };
  const validateMessages = {
    required: "'${label}' is Required!",
    // ...
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  return (
    <Row style={{ justifyContent: "center" }}>
      <Col xs={24} sm={18} lg={12}>
        <h1 style={{ fontSize: "30px", color: "#a0905d" }}>
          <EditOutlined /> Create Event
        </h1>

        <FormContainer>
          <Form
            name="createEventForm"
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={{
              recurring: "Once",
              description: "",
              duration: 0,
              weekly: false,
            }}
          >
            <Form.Item
              label="Event Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="elevated" type="text" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea className="elevated" type="text" />
            </Form.Item>
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                label="Guestlist Size"
                name="maxGuests"
                style={{ width: "50%" }}
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{
                    borderBottomLeftRadius: "8px",
                    borderTopLeftRadius: "8px",

                    width: "100%",
                  }}
                  className="inlineInput"
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label="Cover Price"
                name="coverPrice"
                style={{ width: "50%" }}
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                    borderBottomRightRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                  className="inlineInput"
                  min={0}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                label="Cut Off Time"
                name="cutOffTime"
                style={{ width: "50%" }}
                rules={[{ required: true }]}
              >
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  minuteStep={5}
                  showNow={false}
                  className="inlineInput"
                  style={{
                    width: "100%",
                    borderBottomLeftRadius: "8px",
                    borderTopLeftRadius: "8px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Date"
                name="date"
                style={{ width: "50%" }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  className="inlineInput"
                  disabledDate={disabledDate}
                  style={{
                    width: "100%",
                    borderBottomRightRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                label="Duration"
                name="duration"
                style={{ width: "50%" }}
              >
                <Select
                  className="inlineInput"
                  disabled={!hasDuration}
                  style={{
                    width: "100%",
                  }}
                >
                  <Select.Option value={0}>None</Select.Option>
                  <Select.Option value={1}>1 month</Select.Option>
                  <Select.Option value={3}>3 months</Select.Option>
                  <Select.Option value={6}>6 months</Select.Option>
                  <Select.Option value={12}>1 year</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Weekly"
                name="weekly"
                style={{ width: "33%", marginLeft: 15 }}
              >
                <Switch
                  onChange={(val) => {
                    console.log(val);
                    if (val === true) {
                      setHasDuration(true);
                    } else {
                      setHasDuration(false);
                    }
                  }}
                />
              </Form.Item>
            </div>

            <Form.Item label="Event Image" name="image">
              {progress && <Progress percent={progress} status="active" />}
              <Upload
                fileList={fileList}
                listType="picture"
                customRequest={customUpload}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
              >
                <Button className="elevated">
                  <UploadOutlined /> Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      </Col>
    </Row>
  );
};

export default CreateEventForm;
