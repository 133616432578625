import React, { useState, useEffect, useContext } from "react";
import {
  List,
  Row,
  Col,
  Progress,
  Card,
  Radio,
  Input,
  Button,
  Form,
  InputNumber,
  Modal,
  DatePicker,
  Space,
  Pagination,
  TimePicker,
  Select,
  Upload,
  message,
} from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  CalendarOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { storage } from "../../../firebase";
import VenueContext from "../../../contexts/VenueContext";

const ActiveEventsList = ({
  activeEvents,
  pastEvents,
  handleDelete,
  handleEdit,
  history,
  deleteHelper,
  deletePhoto,
}) => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [data, setData] = useState([]);
  const [showCreate, setShowCreate] = useState(true); //whether to show the Create button or not. If true we show active event if false we show past events
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [editForm] = Form.useForm();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const location = useLocation();

  const { venueId } = useContext(VenueContext);
  const [fileList, setFilelist] = useState([]);
  const [progress, setProgress] = useState(null);
  const [recurring, setRecurring] = useState(false); //tracks whether the event clicked is a recurring event
  const [deleteRecurring, setDeleteRecurring] = useState(false); //tracks whether a user wants to delete following recurring events

  useEffect(() => {
    if (!showCreate) {
      setData(pastEvents);
    } else {
      setData(activeEvents);
    }

    if (location.state) {
      openEditModal(location.state.event);
      history.replace({ pathname: location.pathname, state: undefined });
    }
  }, [activeEvents, showCreate, pastEvents, location]);

  const customUpload = ({ onError, file }) => {
    //Uploads images to Firebase
    const metadata = {
      contentType: "image/jpeg",
    };
    const storageRef = storage.ref();
    const imageName = file.name.split(".")[0] + file.uid;
    const uploadTask = storageRef
      .child(`images/${venueId}/events/${imageName}.jpg`)
      .put(file, metadata);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var downloadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + downloadProgress + "% done");
        setProgress(Math.round(downloadProgress));
      },
      function (error) {
        // Handle unsuccessful uploads
        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        setProgress(null);
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          const img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: downloadURL,
            thumbUrl: downloadURL,
          };
          console.log(img);
          setFilelist([...fileList, img]);
        });
      }
    );
  };
  const beforeUpload = (file) => {
    if (fileList.length === 1) {
      message.error("You can only upload one image for an event");
      return false;
    }
    //checks to see if it is a valid image file
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("You can only upload an image file!");
    }
    return isImage;
  };

  const onRemove = async (file) => {
    console.log(selectedEvent);
    console.log(file);
    try {
      if (selectedEvent.weekly && selectedEvent.image.uid === file.uid) {
        console.log("Same image");
        await deleteHelper(selectedEvent);
      } else {
        console.log("Not the same");
        await deletePhoto(file);
      }

      setFilelist([]);
    } catch (err) {
      console.log(err);
    }
  };
  const onEdit = (values) => {
    console.log(values);
    if (fileList.length === 0) {
      message.error("Image is required!");
      return;
    }
    let data = {
      ...values,
      image: fileList[0],
    };
    const eventId = editForm.getFieldValue("id");
    const recurringId = editForm.getFieldValue("recurringId");

    handleEdit(data, eventId, recurringId);
    setVisible(false);
  };

  const openEditModal = (event) => {
    setVisible(true);
    setFilelist([event.image]);
    if (event.weekly) {
      setRecurring(true);
    }
    editForm.setFieldsValue({
      ...event,
      updateRecurring: false,
      date: moment(event.date),
      cutOffTime: moment(event.date),
    });
  };

  const validateMessages = {
    required: "'${label}' is Required!",
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  if (!data) {
    return <h1>Loading ...</h1>;
  } else {
    let currentData = [...data];
    if (search === "") {
      //handle which data to show based on the page number
      const lastIndex = pageNumber * pageSize;
      const firstIndex = lastIndex - pageSize;
      currentData = data.slice(firstIndex, lastIndex);
    }
    return (
      <Row>
        <Col span={24}>
          <h1 style={{ fontSize: "30px", color: "#a0905d" }}>
            <CalendarOutlined /> Manage Events
          </h1>
        </Col>
        <Col span={24}>
          <Card
            className="elevated"
            style={{ borderRadius: "10px" }}
            title={window.innerWidth < 800 ? "" : "Events"}
            bordered={false}
            extra={
              <Space
                style={{ width: "100%" }}
                direction={window.innerWidth < 700 ? "vertical" : "horizontal"}
              >
                <Radio.Group
                  defaultValue="Upcoming_Events"
                  style={{ width: "100%" }}
                >
                  <Radio.Button
                    style={
                      showCreate
                        ? {
                            color: "white",
                            backgroundColor: "#a0905d",
                            borderColor: "#a0905d",
                          }
                        : {
                            color: "#a0905d",
                            borderColor: "#a0905d",
                          }
                    }
                    value="Upcoming_Events"
                    onClick={() => {
                      setData(activeEvents);
                      setShowCreate(true);
                    }}
                  >
                    Upcoming Events
                  </Radio.Button>
                  <Radio.Button
                    style={
                      !showCreate
                        ? {
                            color: "white",
                            backgroundColor: "#a0905d",
                            borderColor: "#a0905d",
                          }
                        : {
                            color: "#a0905d",
                            borderColor: "#a0905d",
                          }
                    }
                    value="Past_Events"
                    onClick={() => {
                      setData(pastEvents);
                      setShowCreate(false);
                    }}
                  >
                    Past Events
                  </Radio.Button>
                </Radio.Group>
                <Input
                  placeholder="Search by name or date"
                  value={search}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearch(value);
                    setData(() => {
                      //filter events by the value typed in the search bar
                      const prevState = showCreate ? activeEvents : pastEvents;
                      const newData = prevState.filter((event) => {
                        const date1 = moment(event.date)
                          .utc()
                          .format("MMM DD YYYY");
                        const date2 = moment(event.date)
                          .utc()
                          .format("MMMM DD YYYY");
                        const val = value
                          .toLowerCase()
                          .replace(/ +/g, "")
                          .trim();
                        return (
                          event.name
                            .toLowerCase()
                            .replace(/ +/g, "")
                            .indexOf(val) !== -1 ||
                          date1
                            .toLowerCase()
                            .replace(/ +/g, "")
                            .indexOf(val) !== -1 ||
                          date2
                            .toLowerCase()
                            .replace(/ +/g, "")
                            .indexOf(val) !== -1
                        );
                      });
                      return newData;
                    });
                  }}
                  style={{
                    width: "100%",
                    borderColor: "#a0905d",
                    color: "#a0905d",
                    height: "32px",
                  }}
                />
              </Space>
            }
          >
            {showCreate && (
              <Button
                type="default"
                onClick={() => history.push("/owners/portal/events/create")}
                block
                style={{ borderColor: "#a0905d", color: "#a0905d" }}
              >
                <PlusOutlined /> Create Event
              </Button>
            )}

            <ListContainer>
              <List
                itemLayout={window.innerWidth < 700 ? "vertical" : "horizontal"}
                dataSource={currentData}
                bordered
                renderItem={(event) => {
                  return (
                    <List.Item
                      actions={[
                        showCreate && (
                          <a
                            style={{ color: "#a0905d" }}
                            onClick={() => {
                              console.log(event);
                              openEditModal(event);
                              setSelectedEvent(event);
                            }}
                          >
                            Edit
                          </a>
                        ),
                        <a
                          onClick={() => {
                            if (event.weekly) {
                              setRecurring(true);
                            }
                            setVisible2(true);
                            setSelectedEvent(event);
                          }}
                          style={{ color: "#a0905d" }}
                        >
                          Delete
                        </a>,
                        <Link
                          to={{
                            pathname: "/owners/portal/events/details",
                            state: { eventId: event.id, upcoming: showCreate },
                          }}
                          style={{ color: "#a0905d" }}
                        >
                          View Guestlist
                        </Link>,
                      ]}
                    >
                      <List.Item.Meta
                        title={<p>{event.name}</p>}
                        description={
                          <p>
                            Cover Price: ${event.coverPrice}, Cut Off Time:{" "}
                            {event.cutOffTime}
                          </p>
                        }
                      />
                      <ListContent>
                        <DateContainer>
                          <p>{moment(event.date).format("ddd DD MMM YYYY")}</p>
                        </DateContainer>
                        <Progress
                          percent={Math.round(
                            (event.numGuests / event.maxGuests) * 100
                          )}
                          strokeColor="#a0905d"
                        ></Progress>
                      </ListContent>
                    </List.Item>
                  );
                }}
              />
            </ListContainer>
          </Card>
          <Pagination
            style={{ marginTop: "10px", float: "right", color: "black" }}
            current={pageNumber}
            onChange={(page) => setPageNumber(page)}
            defaultPageSize={pageSize}
            showSizeChanger={false}
            total={data.length}
          />
        </Col>
        <Modal
          title="Edit Event"
          visible={visible}
          onOk={() => editForm.submit()}
          onCancel={() => {
            setVisible(false);
          }}
          footer={[
            <Button key="back" onClick={() => setVisible(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => editForm.submit()}
            >
              Edit
            </Button>,
          ]}
        >
          <FormContainer>
            <Form
              form={editForm}
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={(values) => onEdit({ ...values })}
            >
              <Form.Item
                label="Event Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input className="elevated" type="text" />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea className="elevated" type="text" />
              </Form.Item>
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  label="Guestlist Size"
                  name="maxGuests"
                  style={{ width: "50%" }}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{
                      borderBottomLeftRadius: "8px",
                      borderTopLeftRadius: "8px",

                      width: "100%",
                    }}
                    className="inlineInput"
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Cover Price"
                  name="coverPrice"
                  style={{ width: "50%" }}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                      borderBottomRightRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                    className="inlineInput"
                    min={0}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  label="Cut Off Time"
                  name="cutOffTime"
                  style={{ width: "50%" }}
                  rules={[{ required: true }]}
                >
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    minuteStep={5}
                    showNow={false}
                    className="inlineInput"
                    style={{
                      width: "100%",
                      borderBottomLeftRadius: "8px",
                      borderTopLeftRadius: "8px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Date"
                  name="date"
                  style={{ width: "50%" }}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    className="inlineInput"
                    disabledDate={disabledDate}
                    style={{
                      width: "100%",
                      borderBottomRightRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item label="Event Image" rules={[{ required: true }]}>
                {progress && <Progress percent={progress} status="active" />}
                <Upload
                  fileList={fileList}
                  listType="picture"
                  customRequest={customUpload}
                  beforeUpload={beforeUpload}
                  onRemove={onRemove}
                >
                  <Button className="elevated">
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
              {recurring && (
                <Form.Item label="Update" name="updateRecurring">
                  <Select className="inlineInput" style={{ width: "50%" }}>
                    <Select.Option value={false}>Just this event</Select.Option>
                    <Select.Option value={true}>
                      This and following events
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Form>
          </FormContainer>
        </Modal>

        <Modal
          title="Delete Event"
          visible={visible2}
          onOk={() => setVisible2(false)}
          onCancel={() => {
            setDeleteRecurring(false);
            setVisible2(false);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setDeleteRecurring(false);
                setVisible2(false);
              }}
            >
              Cancel
            </Button>,

            <Button
              key="submit"
              type="danger"
              onClick={() => {
                handleDelete(selectedEvent, deleteRecurring);
                setVisible2(false);
                setDeleteRecurring(false);
              }}
            >
              Delete
            </Button>,
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <h4 style={{ marginTop: 5 }}>
              Are you sure you would like to delete{" "}
            </h4>
            {recurring ? (
              <Select
                value={deleteRecurring}
                onSelect={(value) => setDeleteRecurring(value)}
                className="inlineInput"
                style={{ marginLeft: 10, minWidth: 200 }}
              >
                <Select.Option value={false}>This event</Select.Option>
                <Select.Option value={true}>
                  This and following events
                </Select.Option>
              </Select>
            ) : (
              <h4 style={{ marginTop: 5, marginLeft: 2 }}> this event?</h4>
            )}
          </div>

          <p style={{ marginTop: 5 }}>
            This will delete the guestlist and the event information for this
            event {deleteRecurring && " and following weekly events"}.
          </p>
        </Modal>
      </Row>
    );
  }
};

export default ActiveEventsList;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
`;
const DateContainer = styled.div`
  margin-right: 10px;
`;

const ListContainer = styled.div`
  background: white;
`;

const FormContainer = styled.div`
  label {
    color: black;
  }
`;
