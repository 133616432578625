import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyAtg59Day13IUSS9wmf7H8zyBCtTUTBBe4",
//   authDomain: "tipsisocial-142fd.firebaseapp.com",
//   databaseURL: "https://tipsisocial-142fd.firebaseio.com",
//   projectId: "tipsisocial-142fd",
//   storageBucket: "tipsisocial-142fd.appspot.com",
//   messagingSenderId: "1035405079208",
//   appId: "1:1035405079208:web:2e84b047dabd811fc2c89d",
//   measurementId: "G-Q71VZ7EBK6",
// };
const firebaseConfig = {
  apiKey: "AIzaSyC4I4q8wm5jHAwXB6QTYtnmkX__qIM3sVw",
  authDomain: "tipsisocial-5e897.firebaseapp.com",
  projectId: "tipsisocial-5e897",
  storageBucket: "tipsisocial-5e897.appspot.com",
  messagingSenderId: "286726976364",
  appId: "1:286726976364:web:2f6165982e073d18c0f949",
  measurementId: "G-P65CSJDH9M",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const fieldValue = firebase.firestore.FieldValue;
export const firebaseAuth = firebase.auth;
