import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
/**change the import statement for the images */
import Illus1src from '../../../assets/img/undraw_push_notifications_im0o.png'
import Illus2src from '../../../assets/img/undraw_progress_data_4ebj.png'
import Illus3src from '../../../assets/img/undraw_next_tasks_iubr.png'

const WhyTipsi = () => (
  <LandingContentWrapper id="why-tipsi">
    <TitleWrapper>
      <Title>
        Why TipsiSocial?
      </Title>
    </TitleWrapper>
    <ContentRow>
      <ContentCol sm={24} md={8}>
          <Illus1 alt="" src={Illus1src} />
        <BlockTitle>Join The Digital Trend</BlockTitle>
        <BlockDescription>Join the community to be easily
          accessible to new clients.</BlockDescription>
      </ContentCol>
      <ContentCol2 sm={24} md={8}>
          <Illus2 alt="" src={Illus2src} />
        <BlockTitle>Attract New Clients</BlockTitle>
        <BlockDescription>Become a part of the TipsiSocial
          app catalogue that gives clients
          the ability to easily discover your
          establishment.</BlockDescription>
      </ContentCol2>
      <ContentCol3 sm={24} md={8}>
          <Illus3 alt="" src={Illus3src} />
        <BlockTitle>Manage Guestlist and Events</BlockTitle>
        <BlockDescription>Create new events, view past events and manage
          future events and their guestlists with ease.</BlockDescription>
      </ContentCol3>
    </ContentRow>
  </LandingContentWrapper>
);

export default WhyTipsi;

const BlockDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
` 

const Illus1 = styled.img`
  height: 204px;
  width: 293px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  @media only screen and (max-width: 475px){
      height: 174px;
      width: 263px;
  }

  @media only screen and (max-width: 1480px){
      height: 200px;
  }

  @media only screen and (max-width: 1197px){
      height: 174px;
      width: 263px;
  }

  @media only screen and (max-width: 1065px){
      height: 155px;
      width: 253px; 
  }

  @media only screen and (max-width: 990px){
      height: 145px;
      width: 200px; 
  }
`
const Illus2 = styled.img`
  height: 220px;
  width: 339px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @media only screen and (max-width: 475px){
      height: 160px;
      width: 280px;
  }

  @media only screen and (max-width: 1480px){
      height: 210px;
      width: 320px;
  }

  @media only screen and (max-width: 1197px){
      height: 180px;
      width: 290px;
  }

  @media only screen and (max-width: 1065px){
      height: 170px;
      width: 250px; 
  }

  @media only screen and (max-width: 990px){
      height: 150px;
      width: 200px; 
  }
`
const Illus3 = styled.img`
  height: 220px;
  width: 375px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @media only screen and (max-width: 475px){
      height: 180px;
      width: 240px;
  }

  @media only screen and (max-width: 1480px){
      height: 210px;
      width: 330px;
  }

  @media only screen and (max-width: 1197px){
      height: 180px;
      width: 290px;
  }

  @media only screen and (max-width: 1065px){
      height: 170px;
      width: 250px 
  }

  @media only screen and (max-width: 990px){
      height: 150px;
      width: 200px 
  }
`
const LandingContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 100%;
  padding-top:50px;
  will-change: transform;
  background: black;
  font-family: montserrat
`;

const Title = styled.p`
  font-family:'Roboto', sans-serif;
  font-size: 44px;
  color: white;
  margin-bottom: 16px;
  font-weight: 300;
  margin: 0px auto;
`;

const TitleWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 90px;
  text-align: center;
`;

const ContentRow = styled(Row)`
  position: relative;
  height: 100%;
  top: 25%;
`;

const ContentCol = styled(Col)`
  padding: 0 4%;
  text-align: center;
  display: inline-block;
  padding: 100px;
  transition: box-shadow 0.45s ease 0s;
  opacity: 1;
  width:100%;
  transform: translate(0px, 0px);
  color: white;
  background: #22031F;


  &:hover {
    box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
  }

  @media only screen and (max-width: 600px){
      padding: 20px;
  }

  @media only screen and (max-width: 1480px){
      padding: 40px;
  }

  @media only screen and (max-width: 1065px){
      padding: 50px;
  }

  @media only screen and (max-width: 990px){
      padding: 30px; 
  }
`;

const ContentCol2 = styled(Col)`
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  padding: 100px;
  transition: box-shadow 0.45s ease 0s;
  opacity: 1;
  transform: translate(0px, 0px);
  color: white;
  background: #A62639;


  &:hover {
    box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
  }

  @media only screen and (max-width: 600px){
      padding: 20px;
  }

  @media only screen and (max-width: 1480px){
      padding: 40px;
  }

  @media only screen and (max-width: 1065px){
      padding: 50px;
  }

  @media only screen and (max-width: 990px){
      padding: 30px; 
  }
`;

const ContentCol3 = styled(Col)`
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  padding: 100px;
  transition: box-shadow 0.45s ease 0s;
  opacity: 1;
  transform: translate(0px, 0px);
  color: white;
  background: #8A4F7D;


  &:hover {
    box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
  }

  @media only screen and (max-width: 600px){
      padding: 20px;
  }

  @media only screen and (max-width: 1480px){
      padding: 40px;
  }

  @media only screen and (max-width: 1065px){
      padding: 50px;
  }

  @media only screen and (max-width: 990px){
      padding: 30px; 
  }
`;

const BlockTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 32px;
  margin: 20px auto;
  color: white;
  padding: 0;
  padding-top: 30px;
`;

