import React from "react";
import Button from "antd/lib/button";
import styled from "styled-components";
import { Row } from "antd";
import sharesrc from "../../../assets/img/Share.png";
import logosrc from "../../../assets/img/logo.png";

const Share = () => (
  <MainContainer>
    <Title>SPREAD THE WORD BEFORE ACCESS CODE CLOSES</Title>
    <ImageContainer>
      <ShareImg alt="" src={sharesrc} />
    </ImageContainer>
    <Logo alt="" src={logosrc} />
    <FlexContainer>
      <a
        href="https://www.instagram.com/tipsisocial/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ShareBttn type="primary"> Follow Us </ShareBttn>
      </a>
    </FlexContainer>
  </MainContainer>
);

export default Share;

const MainContainer = styled(Row)`
  min-height: 600px;
  background-color: black;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 163px;
`;
const Title = styled.p`
  font-size: 34px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
`;
const ShareImg = styled.img`
  height: 100%inherit;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 139vh;
  height: 34vh;
  max-width: 850px;
  max-height: 212px;
  align-self: center;
  margin-top: 116px;

  @media only screen and (max-width: 860px) {
    width: 0;
    height: 0;
  }
`;

const Logo = styled.img`
  height: 0;
  width: 0;

  @media only screen and (max-width: 860px) {
    width: 57vw;
    height: 30vw;
    align-self: center;
  }
`;
const ShareBttn = styled(Button)`
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 400;
  padding: 16px 70px;
  width: 212px;
  height: 68px;
  border-width: 3px;
  border-color: #a0905d;
  background-color: black;
  text-align: center;
  margin-top: 60px;
  padding: 14px;

  &:hover {
    background-color: #a0905d;
    border-color: #a0905d;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;
