import React from "react";
import styled from "styled-components";
import { Row } from "antd";

const UConnectWithUs = () => (
  <MainContainer>
    <Title>CONNECT WITH US</Title>
    <SubTitle>
      Feel free to contact us if you have any questions at
      roberto.lentini@tipsisocial.com
    </SubTitle>
  </MainContainer>
);

export default UConnectWithUs;

const MainContainer = styled(Row)`
  background-color: black;
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media only screen and (max-width: 890px) {
    text-align: center;
  }
`;

const Title = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 34px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 400;
  color: #a0905d;
`;
const SubTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  margin-bottom: 60px;
  font-weight: 300;
  color: #a0905d;
`;
