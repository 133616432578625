import React from "react";
import Button from "antd/lib/button";
import styled from "styled-components";
import screenshotsrc from "../../../assets/img/OwnerAppScreenshot.png";
import { Row, Col } from "antd";

const OwnerBanner = () => {
  return (
    <BannerMainContainer>
      <ImageContainer>
        <ScreenShot alt="" src={screenshotsrc} />
      </ImageContainer>
      <TextContainer>
        <BannerTitle>
          The Power Of Nightlife Management At Your Fingertips
        </BannerTitle>
        <SubTitleContainer>
          <BannerContent>
            We offer owners and operators a new way to manage their guestlist
            and attract new clientele to their establishment.
          </BannerContent>
        </SubTitleContainer>
        <ButtonContainer>
          <BannerButton
            key="button"
            type="link"
            href="#registrationForm"
            size="large"
          >
            {" "}
            Registration{" "}
          </BannerButton>
        </ButtonContainer>
      </TextContainer>
    </BannerMainContainer>
  );
};

export default OwnerBanner;

const BannerMainContainer = styled(Row)`
  height: 100%;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
`;
const ImageContainer = styled(Col)`
  width: 270px;
  height: 560px;
  order: 1;

  @media only screen and (max-width: 752px) {
    margin: 25px auto;
    height: 496px;
    width: 246px;
    order: 2;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 80px;
  }
`;
const TextContainer = styled(Col)`
  width: 46.875vw;
  height: 300px;
  margin-top: 9%;
  margin-left: 5%;
  max-width: 700px;
  order: 2;

  @media only screen and (max-width: 752px) {
    padding-top: 50px;
    padding-bottom: 20px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    align-self: center;
    width: 100%;
    order: 1;
  }
`;
const ScreenShot = styled.img`
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 752px) {
  }
`;
const BannerTitle = styled.div`
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 300;
  font-size: 44px;

  @media only screen and (max-width: 752px) {
    text-align: center;
    font-size: 32px;
  }
`;
const BannerContent = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: white;
  width: 41.92vw;
  max-width: 600px;

  @media only screen and (max-width: 752px) {
    text-align: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }
`;
const SubTitleContainer = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;

  @media only screen and (max-width: 752px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const ButtonContainer = styled.div`
  width: 0px;
  height: 0px;

  @media only screen and (max-width: 752px) {
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
  }
`;
const BannerButton = styled(Button)`
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s ease-out, box-shadow 0.45s ease-out;
  margin-top: 10px;
  margin-bottom: 10px;
  &:hover {
    color: #fff;
    border-color: #fff;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
  }
  &:focus {
    color: #fff;
    border-color: #fff;
  }
  &.queue-anim-leaving {
    width: auto;
  }
`;
