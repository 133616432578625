import React, { useState, useEffect } from "react";
import { Upload, Button, Row, Col, Form, Input, Select, message, Progress, TimePicker } from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import { storage } from "../../../firebase";
import moment from "moment";
import geoCode from "../../../services/GoogleGeocodeAPI";
const { RangePicker } = TimePicker;

const VenueDetails = ({ venue, updateVenue, venueId }) => {
  //Allows users to change their Venue Profile Info
  const [loading, setLoading] = useState(true);
  const [fileList, setFilelist] = useState([]);
  const [progress, setProgress] = useState(null);
  const [deleteFiles, setDeleteFiles] = useState([]);
  useEffect(() => {
    let isReady = true;
    const setUp = () => {
      if (Object.keys(venue).length !== 0) {
        setFilelist(venue.pictures);
      }
      if (isReady) {
        setLoading(false);
      }
    };

    setUp();
    return () => {
      isReady = false;
    };
  }, [venue]);

  const handleSubmit = async (values) => {
    try {
      // Obtain the lattitude and longitude of the club location and the formatted_address
      const response = await geoCode(values.address, values.city, values.province);
      let coords = response.data.results[0].geometry.location; //lattitude and longitude coords
      let formattedAddress = response.data.results[0].formatted_address; //properly formatted address
      console.log(values);
      const data = {
        ...values,
        pictures: [...fileList],
        hours: {
          start: values.hours[0].format(),
          end: values.hours[1].format(),
        },
        formattedAddress,
        ...coords,
      };

      deletePhotos();
      updateVenue(data);
    } catch (err) {
      console.log(err);
      message.error("Unable to process the location details provided. Please try again");
    }
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const customUpload = ({ onError, file }) => {
    //Uploads images to Firebase
    const metadata = {
      contentType: "image/jpeg",
    };
    const storageRef = storage.ref();
    const imageName = file.name.split(".")[0];
    const uploadTask = storageRef.child(`images/${venueId}/${imageName}.jpg`).put(file, metadata);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var downloadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + downloadProgress + "% done");
        setProgress(Math.round(downloadProgress));
      },
      function (error) {
        // Handle unsuccessful uploads
        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        setProgress(null);
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);
          const img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: downloadURL,
            thumbUrl: downloadURL,
          };
          console.log(img);
          setFilelist([...fileList, img]);
        });
      }
    );
  };
  const beforeUpload = (file) => {
    //checks to see if it is a valid image file
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("You can only upload image file!");
    }
    return isImage;
  };

  const deletePhotos = () => {
    const storageRef = storage.ref();
    deleteFiles.forEach((file) => {
      console.log(file);
      const imageName = file.name.split(".")[0];
      const imgToDelete = storageRef.child(`images/${venueId}/${imageName}.jpg`);
      imgToDelete.delete().then(() => {
        console.log("Successfully deleted image");
      });
    });
  };

  const onRemove = (file) => {
    setDeleteFiles([...deleteFiles, file]);
    setFilelist((prevState) => prevState.filter((img) => img.name !== file.name));
  };

  const validateMessages = {
    required: "'${name}' is Required!",
  };
  console.log(venue);
  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <Row style={{ justifyContent: "center" }}>
        <Col xs={24} sm={18} lg={12}>
          <h1 style={{ fontSize: "30px", color: "#a0905d" }}>
            <EditOutlined /> Venue Profile
          </h1>
          <Form layout="vertical" onFinish={handleSubmit} validateMessages={validateMessages}>
            <Form.Item
              name="name"
              label="Venue Name"
              initialValue={venue && venue.name != undefined ? venue.name : ""}
              rules={[{ required: true }]}
            >
              <Input className="elevated" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Venue Description"
              initialValue={venue && venue.description != undefined ? venue.description : ""}
              rules={[{ required: true }]}
            >
              <Input.TextArea className="elevated" />
            </Form.Item>
            <Form.Item
              name="category"
              label="Venue Type"
              initialValue={venue && venue.category != undefined ? venue.category : ""}
              rules={[{ required: true }]}
            >
              <Select className="elevated">
                <Select.Option value="High End Bars">High End Bars</Select.Option>
                <Select.Option value="Nightclubs">Nightclubs</Select.Option>
                <Select.Option value="Pubs">Pubs</Select.Option>
                <Select.Option value="Live Music Bars">Live Music Bars</Select.Option>
                <Select.Option value="Rooftop Patios">Rooftop Patios</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="dressCode"
              label="Dress Code"
              initialValue={venue && venue.dressCode != undefined ? venue.dressCode : ""}
              rules={[{ required: true }]}
            >
              <Select className="elevated">
                <Select.Option value="Stylish">Stylish</Select.Option>
                <Select.Option value="Casual">Casual</Select.Option>
                <Select.Option value="Business Casual">Business-Casual</Select.Option>
                <Select.Option value="Formal">Formal</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="dressCodeDetails"
              label="Dress Code Details"
              initialValue={venue && venue.dressCodeDetails != undefined ? venue.dressCodeDetails : ""}
            >
              <Input.TextArea className="elevated" placeholder="Added information about the dress code" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone Number"
              initialValue={venue && venue.phone != undefined ? venue.phone : ""}
            >
              <Input className="elevated" />
            </Form.Item>
            <Form.Item name="music" label="Music Genre" initialValue={venue.music} rules={[{ required: true }]}>
              <Select className="elevated" mode="multiple" placeholder="Select music genres" onChange={handleChange}>
                <Select.Option value="Hip-Hop/Rap">Hip-Hop/Rap</Select.Option>
                <Select.Option value="EDM">EDM</Select.Option>
                <Select.Option value="R&B">R&B</Select.Option>
                <Select.Option value="Country">Country</Select.Option>
                <Select.Option value="Rock">Rock</Select.Option>
                <Select.Option value="Pop">Pop</Select.Option>
                <Select.Option value="Latin">Latin</Select.Option>
                <Select.Option value="Jazz">Jazz</Select.Option>
                <Select.Option value="Reggae">Reggae</Select.Option>
                <Select.Option value="Dancehall">Dancehall</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Location"
              initialValue={venue && venue.location != undefined ? venue.location : ""}
              style={{ marginBottom: 0 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  name="province"
                  initialValue={venue && venue.province ? venue.province : ""}
                  style={{ width: "33%" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{
                      borderBottomLeftRadius: "8px",
                      borderTopLeftRadius: "8px",
                    }}
                    className="inlineInput"
                    placeholder="Province"
                  />
                </Form.Item>
                <Form.Item
                  name="city"
                  initialValue={venue && venue.city ? venue.city : ""}
                  style={{ width: "33%" }}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="City" className="inlineInput" />
                </Form.Item>
                <Form.Item
                  name="address"
                  initialValue={venue && venue.address ? venue.address : ""}
                  style={{ width: "33%" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{
                      borderBottomRightRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                    placeholder="Address"
                    className="inlineInput"
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="Venue Hours"
              name="hours"
              initialValue={venue && venue.hours ? [moment(venue.hours.start), moment(venue.hours.end)] : []}
              rules={[{ required: true }]}
            >
              <RangePicker
                style={{ width: "100%" }}
                className="elevated"
                use12Hours
                format="h:mm a"
                minuteStep={5}
                showNow={false}
                order={false}
              />
            </Form.Item>
            <Form.Item label="Venue Images">
              {progress && <Progress percent={progress} status="active" />}
              <Upload
                fileList={fileList}
                listType="picture"
                customRequest={customUpload}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
              >
                <Button className="elevated">
                  <UploadOutlined /> Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
};

export default VenueDetails;
