import React from "react";
import styled from "styled-components";
import hourglassIconsrc from "../../../assets/img/hourglassIcon.png";
import globeIconsrc from "../../../assets/img/globeIcon.png";
import userIconsrc from "../../../assets/img/userIcon.png";
import giftIconsrc from "../../../assets/img/giftIcon.png";
import AppFeaturesScreenShotsrc from "../../../assets/img/AppFeaturesScreenShot.png";

const AppFeatures = () => (
  <MainContainer>
    <TitleContainer>
      <p>App Features</p>
    </TitleContainer>

    <SubtitleContainer>
      <p>
        Giving our Members the ability to control their nightlife with our app.
      </p>
    </SubtitleContainer>
    <PageBodyContainer>
      <ImageContainer>
        <Screenshot alt="" src={AppFeaturesScreenShotsrc} />
      </ImageContainer>

      <SideBarContainer>
        <FeatureComponent>
          <IconContainer>
            <Icon1 alt="" src={hourglassIconsrc} />
          </IconContainer>
          <FeatureTextContainer>
            <FeatureTextTitle>Time.</FeatureTextTitle>
            <p>Forget the line. Get quick entry with the tap of a finger.</p>
          </FeatureTextContainer>
        </FeatureComponent>

        <FeatureComponent>
          <IconContainer>
            <Icon alt="" src={globeIconsrc} />
          </IconContainer>
          <FeatureTextContainer>
            <FeatureTextTitle>Discover.</FeatureTextTitle>
            <p>Find new nightlife venues that suit your style.</p>
          </FeatureTextContainer>
        </FeatureComponent>

        <FeatureComponent>
          <IconContainer>
            <Icon alt="" src={userIconsrc} />
          </IconContainer>
          <FeatureTextContainer>
            <FeatureTextTitle>Customize.</FeatureTextTitle>
            <p>Customize your nightlife profile for a personal experience.</p>
          </FeatureTextContainer>
        </FeatureComponent>

        <FeatureComponent>
          {/* <IconContainer>
            <Icon alt="" src={giftIconsrc} />
          </IconContainer>
          <FeatureTextContainer>
            <FeatureTextTitle>Events.</FeatureTextTitle>
            <p>Plan ahead and buy VIP packages for those special events.</p>
          </FeatureTextContainer> */}
        </FeatureComponent>
      </SideBarContainer>
    </PageBodyContainer>
  </MainContainer>
);

export default AppFeatures;

const MainContainer = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 890px) {
    text-align: center;
    margin-top: -20px;
  }
`;
/*Make it so that the animation only activated when you scroll to the page */
const TitleContainer = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 200px;
  font-size: 34px;
  @media only screen and (max-width: 890px) {
    margin-bottom: 5px;
    align-self: center;
    margin-top: 125px;
  }
`;
const SubtitleContainer = styled.div`
  font-family: "Roboto", "sans-serif";
  font-size: 18px;
  font-weight: 300;
  color: white;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 5px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
`;
const ImageContainer = styled.div`
  height: 580px;
  width: 290px;
  margin-top: 60px;

  @media only screen and (max-width: 890px) {
    align-self: center;
  }
`;
const PageBodyContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 890px) {
    flex-direction: column;
    background-color: black;
  }
`;
const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 180px;
  margin-top: 60px;
  margin-bottom: 50px;
  @media only screen and (max-width: 890px) {
    padding-top: 20px;
    padding-left: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
const FeatureComponent = styled.div`
  display: flex;
  margin-bottom: 70px;
  line-height: 27px;
  @media only screen and (max-width: 890px) {
    margin-bottom: 60px;
  }
`;
const FeatureTextContainer = styled.div`
  font-family: "Roboto", "sans-serif";
  font-size: 18px;
  font-weight: 300;
  color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  @media only screen and (max-width: 450px) {
    width: 225px;
  }
`;
const IconContainer = styled.div`
  height: 70px;
  width: 70px;
  border: solid 2px #a0905d;
  transform: rotate(45deg);
  margin-right: 45px;
  @media only screen and (max-width: 450px) {
    margin-right: 30px;
  }
`;
const FeatureTextTitle = styled.p`
  font-size: 20px;
  color: #a0905d;
  font-weight: bold;
`;
const Icon1 = styled.img`
  height: 21px;
  width: 19px;
  transform: rotate(-45deg);
  margin: 23px;
`;
const Icon = styled.img`
  height: 21px;
  width: 22px;
  transform: rotate(-45deg);
  margin: 22px;
`;
const Screenshot = styled.img`
  width: 100%;
  height: 100%;
  background: black;
`;
