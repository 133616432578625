import React, { useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Form, Input, Button, Checkbox, Row, message } from "antd";
import { auth } from "../../firebase";
import UserContext from "../../contexts/UserContext";
import UserbackgroundImg from "../../assets/img/bckimg.png";
import styled from "styled-components";

const Login = () => {
  const { currentUser } = useContext(UserContext);
  const history = useHistory();

  const login = async (values) => {
    const { email, password } = values;
    try {
      await auth.signInWithEmailAndPassword(email, password);
      history.push("/owners/portal");
    } catch (err) {
      console.log(err.message);
      const errorMessage = err.message;
      message.error(errorMessage);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (currentUser) {
    return <Redirect to="/owners/portal" />;
  }

  return (
    <BannerMainContainer type="flex" justify="center" align="middle">
      <div>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={login}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={
              <p
                style={{
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  marginTop: "17px",
                }}
              >
                Email
              </p>
            }
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <p
                style={{
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  marginTop: "17px",
                }}
              >
                Password
              </p>
            }
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
            name="remember"
            valuePropName="checked"
          >
            <Checkbox
              style={{ color: "white", fontFamily: "Roboto", fontSize: "16px" }}
            >
              Remember me
            </Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
        {/* removed till lanch of owner portal
        <p type="flex" align="middle">
          New user?
          {' '}
          <Link to="/signup">
            Sign up here
          </Link>
          <br />
          {' '}
          <Link to="/forgot-password">
            Forgot Password?
          </Link>
        </p>
         */}
      </div>
    </BannerMainContainer>
  );
};

export default Login;

const BannerMainContainer = styled(Row)`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${UserbackgroundImg});
  height: 100vh;
  background-color: transparent;
  display: flex;
  padding-top: 20vh;
  padding-bottom: 5%;
  justify-content: center;

  @media only screen and (max-height: 1030px) {
    padding-top: 5%;
  }

  @media only screen and (max-width: 752px) {
    height: 950px;
  }
`;
