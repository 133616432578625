import React from 'react';
import Menu from 'antd/lib/menu';
import { NavLink, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TIPSISOCIAL from '../../../assets/img/TIPSISOCIAL.png';

const Nav = styled.div`
  z-index: 1;
  box-shadow: 0 5px 8px fade(#000, 15);
  position: relative;
  top: 0;
`;

const NavLogo = styled(Link)`
  position: relative;
  width: 120px;
  background: transparent;
  color: white;
  float: left;
  border: none;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: white;
  }

  &:focus {
    outline: none;
  }

`;

const Logo = styled.img`
  width: 160px;
  height: 30px;
`
const UserMenu = styled(Menu)`
  background-color: transparent;
  height: 69px;
  border-bottom:0px;
  float: right;
`

const MenuItem = styled(Menu.Item)`
  
  color: white;
  font-weight: 500;
  font-size: 1rem;

  &&.ant-menu-item-selected {
    background-color: white;
    color: black;
  }
`;

const UserNav = () => {
  const location = useLocation();
  console.log(location)
  return (
    <Nav className="nav">
      <NavLogo to="/">
        <Logo src={TIPSISOCIAL} />
      </NavLogo>
      <UserMenu mode="horizontal">
        <MenuItem>
          <span>For Owners</span>
          <NavLink to="/owners" />
        </MenuItem>
      </UserMenu>
    </Nav>
  );
};

export default UserNav;
