import React, { useEffect, useState } from "react";
import { Row, Col, Card, Statistic, Progress, Descriptions, Carousel, Select } from "antd";
import styled from "styled-components";
import { HomeOutlined, EditOutlined, TeamOutlined, ArrowUpOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { firestore } from "../../../firebase";
import { Link } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const PortalHome = ({ venue, venueId, sortEvents, setEndDate }) => {
  const [activeEvents, setActiveEvents] = useState(null);
  const [pastEvents, setPastEvents] = useState(null);
  const [averageGC, setAverageGC] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("events")
      .where("venueId", "==", venueId)
      .onSnapshot(
        (querySnapshot) => {
          const eventsData = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const data = doc.data();
            data.id = doc.id;
            eventsData.push(data);
          });
          const res = sortEvents(eventsData);
          setActiveEvents(res.active_events);
          setPastEvents(res.past_events);
          averageGuestlistCapacity(res.past_events);
          setChartData(getChartData(res.past_events, 5));
          setLoading(false);
        },
        function (error) {
          console.log("Error getting document:", error);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [sortEvents, venueId]);

  const getChartData = (events, num) => {
    const cdata = events.slice(0, num).map((event) => {
      return {
        name: event.name,
        NumberOfGuests: event.numGuests,
        MaxGuestlistCapacity: event.maxGuests,
        rest: event.maxGuests - event.numGuests,
        percent: Math.round((event.numGuests / event.maxGuests) * 100),
      };
    });

    return cdata;
  };
  const averageGuestlistCapacity = (events) => {
    let guests = 0;
    let total = 0;

    events.forEach((event) => {
      guests += event.numGuests;
      total += event.maxGuests;
    });
    setAverageGC(Math.round((guests / total) * 100));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    //tooltip for the chart
    if (active) {
      if (!payload) {
        return null;
      }
      const numOfGuests = payload[0].value;
      const maxNumGuests = payload[0].value + payload[1].value;
      return (
        <div style={{ backgroundColor: "rgba(255,255,255,0.7)", padding: "10px" }}>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          <p>Number of Guests: {numOfGuests}</p>
          <p>Number of Guests: {maxNumGuests}</p>
          <p>Guestlist Capacity: {Math.round((numOfGuests / maxNumGuests) * 100)}%</p>
        </div>
      );
    }

    return null;
  };
  const renderLegend = ({ payload }) => {
    //legend for the chart
    return (
      <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
        <li className="recharts-legend-item legend-item-0" style={{ display: "inline-block", marginRight: "10px" }}>
          <svg
            className="recharts-surface"
            width="14"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          >
            <path stroke="none" fill="#a0905d" d="M0,4h32v24h-32z" className="recharts-legend-icon"></path>
          </svg>
          <span className="recharts-legend-item-text">Number Of Guests</span>
        </li>
      </ul>
    );
  };

  const checkIfCurrentEvent = (date) => {
    //checks if the upcoming event's date is today
    const startDate = new Date(moment(date).utc().format("MMM DD YYYY"));
    const endDate = setEndDate(new Date(moment(date).utc().format("MMM DD YYYY")));
    const today = new Date();
    return startDate < today && today < endDate;
  };
  if (!venue && loading) {
    return <h1>Loading...</h1>;
  } else {
    let upcoming = {};
    if (activeEvents && activeEvents.length > 0) {
      //get the upcoming event
      upcoming = activeEvents[0];
    }
    let photos = [];
    if (venue.pictures) {
      photos = venue.pictures.map((img, i) => (
        <img key={i} style={{ width: "100%", height: "100%" }} alt="" src={img.url} />
      ));
    }

    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <h1 style={{ fontSize: "30px", color: "#a0905d" }}>
            <HomeOutlined /> Dashboard
          </h1>
        </Col>
        <Col span={24}>
          <Row gutter={[16, { xs: 16, sm: 16, md: 0, lg: 0 }]}>
            <Col xs={24} sm={12} md={6}>
              <Card className="elevated" style={{ height: "100%", borderRadius: "16px", width: "100%" }}>
                <StyledStatistic
                  title="Upcoming Events"
                  value={activeEvents ? activeEvents.length : 0}
                  valueStyle={{ color: "#a0905d" }}
                  prefix={<CalendarOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card className="elevated" style={{ height: "100%", borderRadius: "16px", width: "100%" }}>
                <StyledStatistic
                  title="Total Events Hosted"
                  value={pastEvents ? pastEvents.length : 0}
                  valueStyle={{ color: "#a0905d" }}
                  prefix={<ArrowUpOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card className="elevated" style={{ height: "100%", borderRadius: "16px", width: "100%" }}>
                <StyledStatistic
                  title="Total Guests Served"
                  value={venue.totalGuests}
                  valueStyle={{ color: "#a0905d" }}
                  prefix={<TeamOutlined />}
                />
              </Card>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Card className="elevated" style={{ height: "100%", borderRadius: "16px", width: "100%" }}>
                <StyledStatistic
                  title="Average Guestlist Capacity"
                  value={averageGC}
                  valueStyle={{ color: "#a0905d" }}
                  prefix={<ArrowUpOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card className="elevated" style={{ borderRadius: "16px" }} bodyStyle={{ paddingLeft: 0 }}>
                <Row>
                  <Col span={18}></Col>
                  <Col span={6}>
                    <Select
                      defaultValue={5}
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setChartData(getChartData(pastEvents, value));
                      }}
                    >
                      <Select.Option value={5}>Last 5 Events</Select.Option>
                      <Select.Option value={10}>Last 10 Events</Select.Option>
                      <Select.Option value={20}>Last 20 Events </Select.Option>
                    </Select>
                  </Col>
                </Row>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={renderLegend} />
                    <Bar dataKey="NumberOfGuests" stackId="a" fill="#a0905d" barSize={20} />
                    <Bar dataKey="rest" stackId="a" fill="#e0e0e0" barSize={20} />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Col>
            <Col span={24}>
              {Object.keys(upcoming).length > 0 ? (
                <StyledEventCard
                  className="elevated"
                  style={{ borderRadius: "16px" }}
                  title={upcoming && checkIfCurrentEvent(upcoming.date) ? "Current Event" : "Upcoming Event"}
                  actions={[
                    <Link
                      to={{
                        pathname: "/owners/portal/events",
                        state: { event: upcoming },
                      }}
                    >
                      <EditOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
                      Edit Event
                    </Link>,
                    <Link
                      to={{
                        pathname: "/owners/portal/events/details",
                        state: { eventId: upcoming.id, upcoming: true },
                      }}
                    >
                      <TeamOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
                      View Guestlist
                    </Link>,
                  ]}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card
                        style={{ border: 0 }}
                        bodyStyle={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <StyledGuestListProgress
                          type="circle"
                          percent={Math.round((upcoming.numGuests / upcoming.maxGuests) * 100)}
                          strokeColor="#a0905d"
                        />
                        <StyledStatistic
                          title="GuestList Capacity"
                          value={upcoming.numGuests}
                          suffix={`/ ${upcoming.maxGuests}`}
                          style={{ paddingLeft: "20px" }}
                        />
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Descriptions bordered column={1}>
                        <DescriptionsItem label="Date">
                          {upcoming && moment(upcoming.date).utc().format("MMM DD YYYY")}
                        </DescriptionsItem>
                        <DescriptionsItem label="Cover Price">${upcoming && upcoming.coverPrice}</DescriptionsItem>
                        <DescriptionsItem label="Cut Off Time">{upcoming && upcoming.cutOffTime}</DescriptionsItem>
                      </Descriptions>
                    </Col>
                  </Row>
                </StyledEventCard>
              ) : (
                <StyledEventCard className="elevated" style={{ borderRadius: "16px" }} title="Upcoming Event">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card
                        style={{ border: 0 }}
                        bodyStyle={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <StyledGuestListProgress type="circle" percent={0} strokeColor="#a0905d" />
                        <StyledStatistic
                          title="GuestList Capacity"
                          value={0}
                          suffix={"/0"}
                          style={{ paddingLeft: "20px" }}
                        />
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Descriptions bordered column={1}>
                        <DescriptionsItem label="Event">No upcoming event</DescriptionsItem>
                      </Descriptions>
                    </Col>
                  </Row>
                </StyledEventCard>
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={24} lg={12}>
          <StyledVenueCard
            className="elevated"
            style={{ borderRadius: "16px" }}
            title="Venue Profile"
            cover={
              <Carousel
                style={{
                  overflow: "hidden",
                  objectFit: "cover",
                  height: "45vh",
                }}
              >
                {photos}
              </Carousel>
            }
            actions={[
              <Link
                to={{
                  pathname: "/owners/portal/venue",
                }}
              >
                <EditOutlined style={{ fontSize: "20px", marginRight: "5px" }} />
                Edit Venue Profile
              </Link>,
            ]}
          >
            <Card.Meta title={venue.name} style={{ marginBottom: "10px" }} />
            <p>{venue.description}</p>
            {Object.keys(venue).length > 0 && (
              <>
                <p>
                  <strong>Venue Type: </strong>
                  {venue.category}
                </p>
                <p>
                  <strong>Dress code: </strong>
                  {venue.dressCode} - {venue.dressCodeDetails}
                </p>
                <p>
                  <strong>Location: </strong>
                  {venue.address}, {venue.city}, {venue.province}
                </p>
                <p>
                  <strong>Hours: </strong>
                  {venue.hours && moment(venue.hours.start).format("hh:mm a")} to{" "}
                  {venue.hours && moment(venue.hours.end).format("hh:mm a")}
                </p>
                <p>
                  <strong>Music Genre: </strong>
                  {venue.music ? venue.music.join(", ") : ""}
                </p>
              </>
            )}
          </StyledVenueCard>
        </Col>
      </Row>
    );
  }
};

export default PortalHome;

const DescriptionsItem = styled(Descriptions.Item)`
  font-weight: bold;
`;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    margin-bottom: 4px;
    color: #4d4c4b;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-statistic-content-value-int {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
  }
  .ant-statistic-content-suffix {
    font-weight: 500;
  }
`;
const StyledVenueCard = styled(Card)`
  .ant-card-head-title {
    display: inline-block;
    -ms-flex: 1;
    flex: 1 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
  }

  .ant-card-actions > li > span a:not(.ant-btn),
  .ant-card-actions > li > span > .anticon {
    font-size: 14px;
    color: rgb(160, 144, 93);
  }
`;
const StyledEventCard = styled(Card)`
  .ant-card-head {
    border: 0;
  }
  .ant-card-head-title {
    font-size: 20px;
  }
  .ant-card-body {
    display: flex;
    justify-content: center;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 30px;
  }
  .ant-descriptions-bordered .ant-descriptions-view {
    border: 0;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content {
    border: 1px solid #f0f0f0;
    border-radius: 0px 50px 50px 0px;
    border-left: none;
    text-align: center;
    color: black;
    font-size: 14px;
  }
  .ant-descriptions-item-label {
    border-left: none;
    border: 1px solid #f0f0f0;
    border-radius: 50px 0px 0px 50px;
    display: flex;
    justify-content: center;
    color: black;
    font-weight: 500;
    font-size: 14px;
  }

  .ant-card-actions > li > span a:not(.ant-btn),
  .ant-card-actions > li > span > .anticon {
    font-size: 14px;
    color: rgb(160, 144, 93);
  }
`;
const StyledGuestListProgress = styled(Progress)`
  .ant-progress-text {
    color: #a0905d;
    font-weight: 500;
  }
`;
