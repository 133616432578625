import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OwnerLanding from "../OwnerLanding";
import OwnerPortal from "../OwnerPortal";
import Login from "../Login";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
import PrivateRoute from "../../components/PrivateRoute";
import NoMatch from "../../components/NoMatch";
import UserProvider from "../../components/UserProvider";
import VenueProvider from "../../components/VenueProvider";
import UserLanding from "../UserLanding";
import "antd/dist/antd.css";

const App = () => (
  <UserProvider>
    <VenueProvider>
      <Router>
        <Switch>
          {/* children elements as recommended method in react router v5 */}
          <Route exact path="/">
            <UserLanding />
          </Route>
          <Route exact path="/owners">
            <OwnerLanding />
          </Route>
          <Route path="/owners/login">
            <Login />
          </Route>
          <Route path="/owner/signup">
            <SignUp />
          </Route>
          <Route path="/owners/forgot-password">
            <ForgotPassword />
          </Route>
          <PrivateRoute path="/owners/portal">
            <OwnerPortal />
          </PrivateRoute>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </VenueProvider>
  </UserProvider>
);

export default App;
