import React from 'react';
import styled from 'styled-components';

const UserFooterWrapper = styled.div`
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 120px;
  color: #999;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const CopyrightWrapper = styled.div`
  margin-top: 70px;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid rgba(233,233,233,.1);
`;

const UFooter = () => {
  return (
    <UserFooterWrapper>
      <CopyrightWrapper className="copyright-wrapper">
        <span> Copyright ©2020 TipsiSocial </span>
        <span> Icon found on login app design made by Eucalyp from
            www.flaticon.com
        </span>
      </CopyrightWrapper>
    </UserFooterWrapper>
  );
};


export default UFooter;
