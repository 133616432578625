import React from "react";
import Button from "antd/lib/button";
import styled from "styled-components";
/*Make the image darker so that the text is more viewable*/
import screenshotsrc from "../../../assets/img/UserBannerAppScreenshot.png";
import { Row, Col } from "antd";

const UBanner = () => {
  return (
    <BannerMainContainer>
      <ImageContainer>
        <ScreenShot alt="" src={screenshotsrc} />
      </ImageContainer>
      <TextContainer>
        <BannerTitle>The Key To Your Nightlife At Your Finger Tips</BannerTitle>
        <SubTitleContainer>
          <BannerContent>
            We offer our members the ability to get right to the fun by giving
            them the tool to skip lines with the tap of a button
          </BannerContent>
        </SubTitleContainer>
        <ButtonContainer>
          <BannerButton
            key="button"
            type="link"
            href="#becomeamember"
            size="large"
          >
            BECOME A MEMBER
          </BannerButton>
        </ButtonContainer>
      </TextContainer>
    </BannerMainContainer>
  );
};

export default UBanner;

const BannerMainContainer = styled(Row)`
  height: 100vh;
  background-color: transparent;
  display: flex;
  padding-top: 20vh;
  padding-bottom: 5%;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  @media only screen and (max-height: 1030px) {
    padding-top: 5%;
  }

  @media only screen and (max-width: 752px) {
    height: 950px;
  }
`;
const ImageContainer = styled(Col)`
  width: 290px;
  height: 580px;

  @media only screen and (max-width: 752px) {
    margin: auto;
    height: 496px;
    width: 246px;
    order: 2;
    margin-top: 60px;
  }
`;
const TextContainer = styled(Col)`
  width: 40vw;
  height: 300px;
  margin-top: 7%;
  margin-left: 5%;
  max-width: 600px;

  @media only screen and (max-width: 752px) {
    height: 250px;
    margin-left: 0px;
    margin-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
    order: 1;
  }
`;
const ScreenShot = styled.img`
  height: 100%;
  width: 100%;
`;
const BannerTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 44px;

  @media only screen and (max-width: 752px) {
    text-align: center;
    font-size: 32px;
  }
`;
const BannerContent = styled.div`
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  width: 41.92vw;
  max-width: 600px;

  @media only screen and (max-width: 752px) {
    text-align: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }
`;
const SubTitleContainer = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;
`;
const ButtonContainer = styled.div`
  width: 0px;
  height: 0px;
  @media only screen and (max-width: 752px) {
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
  }
`;
const BannerButton = styled(Button)`
  border: 1px solid #a0905d;
  color: white;
  background: #a0905d;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s ease-out, box-shadow 0.45s ease-out;
  margin-top: 5%;
  border-radius: 10px;

  &:hover {
    color: #fff;
    border-color: #fff;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
  }
  &:focus {
    color: #fff;
    border-color: #fff;
  }
  &.queue-anim-leaving {
    width: auto;
  }
`;
