import React from "react";
import styled from "styled-components";
import { Form, Input, Button, Row, Col, message } from "antd";
import { firestore } from "../../../firebase";

const Registration = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values);
    firestore
      .collection("leads")
      .add(values)
      .then((docRef) => {
        console.log(docRef);
        message.success(
          "Thank you for showing interest in TipsiSocial. We'll contact you soon!"
        );
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <MainContainer>
      <FormContainer xs={20} sm={18} md={14} lg={12} id="registrationForm">
        <Title>Meeting Form</Title>
        <SubTitle>
          Fill this form so we can get your venue access to TipsiSocial.
        </SubTitle>
        <FormRow>
          <Col sm={24} md={20} lg={18}>
            <Form name="nest-message" onFinish={onFinish} form={form}>
              <Label>Venue Name</Label>
              <Form.Item
                name={["user", "venueName"]}
                rules={[{ required: true, message: "Venue Name is required!" }]}
              >
                <Input />
              </Form.Item>
              <Label>Email</Label>
              <Form.Item
                name={["user", "email"]}
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input />
              </Form.Item>
              <Label>Full Name</Label>
              <Form.Item
                name={["user", "name"]}
                rules={[{ required: true, message: "Full Name is required!" }]}
              >
                <Input />
              </Form.Item>
              <Label>Phone Number</Label>
              <Form.Item
                name={["user", "phone"]}
                rules={[
                  { required: true, message: "Phone Number is required!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Label>Venue Location</Label>
              <Form.Item>
                <FlexContainer>
                  <Form.Item
                    name={["location", "province"]}
                    noStyle
                    rules={[
                      { required: true, message: "Province is required!" },
                    ]}
                  >
                    <Input placeholder="Province" />
                  </Form.Item>
                  <Form.Item
                    name={["location", "city"]}
                    noStyle
                    rules={[{ required: true, message: "City is required!" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item
                    name={["location", "address"]}
                    noStyle
                    rules={[
                      { required: true, message: "Address is required!" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </FlexContainer>
              </Form.Item>
              <Label>Additional Comments or Questions</Label>
              <Form.Item name={["user", "introduction"]}>
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <ButtonContainer>
                  <Button
                    style={{
                      backgroundColor: "#a0905d",
                      borderColor: "#a0905d",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </ButtonContainer>
              </Form.Item>
            </Form>
          </Col>
        </FormRow>
      </FormContainer>
    </MainContainer>
  );
};

export default Registration;

const MainContainer = styled(Row)`
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  padding: 10px;
`;
const FormContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;
const Title = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 34px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 400;
  text-align: center;
`;
const SubTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  margin-bottom: 60px;
  font-weight: 300;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.p`
  font-size: 18px;
  color: white;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
`;

const FormRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
