import React, { useState, useEffect } from "react";
import CreateEventForm from "../../components/Manage/CreateEventForm";
import ActiveEventsList from "../../components/Manage/ActiveEventsList";
import EventDetails from "../../components/Manage/EventDetails";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { firestore, fieldValue } from "../../firebase";
import { message } from "antd";
import moment from "moment";
import { storage } from "../../firebase";

const ManageEvents = ({ venueId, history, sortEvents }) => {
  const { path } = useRouteMatch();
  const [activeEvents, setActiveEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    //Retrieve all the events for a venue from Firebase
    const unsubscribe = firestore
      .collection("events")
      .where("venueId", "==", venueId)
      .onSnapshot(
        (querySnapshot) => {
          const eventsData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            eventsData.push(data);
          });
          //Sort events
          const res = sortEvents(eventsData);
          setActiveEvents(res.active_events);
          setPastEvents(res.past_events);
        },
        function (error) {
          console.log("Error getting document:", error);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [sortEvents, venueId]);

  const formatDate = (cutOffTime, date) => {
    //creates a new date with the cut off time added to the date of the event
    const hours = moment(cutOffTime).hour();
    const minutes = moment(cutOffTime).minutes();
    console.log(hours, minutes);
    date = moment(date).hours(hours).minutes(minutes);
    return date;
  };

  const handleCreate = (fieldsValue) => {
    const formattedDate = formatDate(fieldsValue.cutOffTime, fieldsValue.date);
    const startDate = moment(formattedDate);
    const endDate = moment(formattedDate).add(fieldsValue.duration, "months");
    // Creates an event
    let num = 7;
    let type = "days";
    let recurringId = null; //recurring id links all recurring events to each other
    const values = {
      ...fieldsValue,
      date: startDate.format(),
      cutOffTime: moment(startDate).format("hh:mm a"),
      venueId,
      guestlist: [],
      guestlistIds: [],
      numGuests: 0,
      recurringId,
    };

    let date = startDate.add(num, type);

    firestore
      .collection("events")
      .add(values)
      .then((docRef) => {
        console.log(docRef.id);
        recurringId = docRef.id;
        return firestore.collection("events").doc(docRef.id).update({
          //updates the inital event to have its own id as the recurring id
          recurringId: docRef.id,
        });
      })
      .then(() => {
        let promises = [];
        while (date <= endDate) {
          //create all the events until the end of duration if a recurring event
          console.log(moment(date).format("dddd MMMM Do YYYY"));
          const values = {
            ...fieldsValue,
            date: date.format(),
            cutOffTime: moment(date).format("hh:mm a"),
            venueId,
            guestlist: [],
            guestlistIds: [],
            numGuests: 0,
            recurringId,
          };
          promises.push(firestore.collection("events").add(values));
          date = date.add(num, type);
        }
        return Promise.all(promises);
      })
      .then(() => {
        message.success("Successfully created events");
      })
      .catch((err) => {
        console.log(err);
        throw Error;
      });
  };

  const handleDelete = (event, deleteAll) => {
    //Deletes an event, then checks if the user wants to delete recurring events, then after it deletes the event image
    console.log(event.id);
    firestore
      .collection("events")
      .doc(event.id)
      .delete()
      .then(() => {
        if (deleteAll && event.recurringId) {
          return deleteRecurring(event.recurringId, event.date);
        }
        return Promise.resolve();
      })
      .then(() => {
        return deleteHelper(event);
      })
      .then(() => {
        console.log("Document successfully deleted!");
        let msg = deleteAll
          ? "Successfully deleted events"
          : "Successfully deleted event";
        message.success(msg);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
        message.error("Error deleting the event/events. Please try again...");
      });
  };

  const deleteHelper = (event) => {
    //keeps track of the length of the remaining recurring events. If the last one was deleted we delete the photo from storage
    firestore
      .collection("events")
      .where("recurringId", "==", event.recurringId)
      .onSnapshot((querySnapshot) => {
        let remainingEvents = [];
        querySnapshot.forEach((doc) => {
          remainingEvents.push(doc.data());
        });
        console.log("REMAINING EVENTS", remainingEvents);
        if (remainingEvents.length == 0) {
          return deletePhoto(event.image);
        }
        return Promise.resolve();
      });
  };

  const deletePhoto = (file) => {
    /*deletes a particular photo from firebase storage*/
    const storageRef = storage.ref();

    console.log(file);
    const imageName = file.name.split(".")[0] + file.uid;
    const imgToDelete = storageRef.child(
      `images/${venueId}/events/${imageName}.jpg`
    );
    return imgToDelete.delete();
  };

  const deleteRecurring = (recurringId, eventDate) => {
    /*deletes all following events*/
    console.log("DELETE RECURRING");
    firestore
      .collection("events")
      .where("recurringId", "==", recurringId)
      .get()
      .then((querySnapshot) => {
        let promises = [];
        querySnapshot.forEach((doc) => {
          let event = doc.data();
          let eventId = doc.id;
          let date = new Date(event.date);
          console.log("DATE: ", date);
          if (date > new Date(eventDate)) {
            promises.push(firestore.collection("events").doc(eventId).delete());
          }
        });

        return Promise.all(promises);
      });
  };

  const handleEdit = (data, id, recurringId) => {
    /*Edit event information
    1st retrieve original event to get original date and compare with the new date and get the difference.
    Then update the event
    If the event is recurring add the difference to the date of each of the following event's and update any other fields*/

    const formattedDate = formatDate(data.cutOffTime, data.date);
    let updateFollowingEvents = data.updateRecurring && recurringId != null; //determines if user wants to update recurring events and if it is a recurring event
    const eventData = {
      ...data,
      date: formattedDate.format(),
      cutOffTime: moment(formattedDate).format("hh:mm a"),
    };

    firestore
      .collection("events")
      .doc(id)
      .update(eventData)
      .then((docRef) => {
        console.log("Document successfully edited!");
        if (updateFollowingEvents) {
          return updateRecurring(eventData, recurringId);
        }
        return Promise.resolve();
      })
      .then(() => {
        message.success("Successfully edited event");
      })
      .catch(function (error) {
        console.error("Error editing document: ", error);
        message.error("Error editing the event. Please try again...");
      });
  };

  const updateRecurring = (eventData, recurringId) => {
    /*Updates all the recurring after the first updated event
     making sure to update the date for each event and the photo. 
     if a photo was changed it deletes the old photo if neccessary*/
    console.log(eventData);
    const startDate = moment(eventData.date);
    let photos = []; //stores all the photos  from the recurring events that are different than the updated photo
    let photoIDSet = new Set(); //stores unique photo ids so we arent adding the same photo to the array photos
    firestore
      .collection("events")
      .where("recurringId", "==", recurringId)
      .get()
      .then((querySnapshot) => {
        let recurringEvents = [];

        querySnapshot.forEach((doc) => {
          let event = { ...doc.data(), id: doc.id };
          let date = new Date(moment(event.date));
          console.log(date);
          if (date > new Date(startDate)) {
            recurringEvents.push(event);
            if (
              event.image.uid != eventData.image.uid &&
              !photoIDSet.has(event.image.uid)
            ) {
              photos.push(event.image);
              photoIDSet.add(event.image.uid);
            }
          }
        });
        recurringEvents.sort((e1, e2) => {
          return new Date(e1.date) - new Date(e2.date);
        });
        console.log("RECURRING EVENTS", recurringEvents);
        let promises = [];
        let date = startDate.add(7, "days");

        recurringEvents.forEach((e) => {
          promises.push(
            firestore
              .collection("events")
              .doc(e.id)
              .update({
                ...eventData,
                date: moment(date).format(),
                cutOffTime: moment(date).format("hh:mm a"),
              })
          );
          date = date.add(7, "days");
        });
        return Promise.all(promises);
      })
      .then(() => {
        return deleteChangedPhotos(photos, recurringId);
      });
  };

  const deleteChangedPhotos = (photos, recurringId) => {
    /*Given an array of all the photos that were changed it checks if there are any events that has the same photo.
    if not then we can safely delete the photo from storage*/
    console.log("DELETE CHANGED PHOTOS");
    firestore
      .collection("events")
      .where("recurringId", "==", recurringId)
      .onSnapshot((querySnapshot) => {
        let promises = [];
        for (let img of photos) {
          let canDelete = true;
          querySnapshot.forEach((doc) => {
            let event = doc.data();
            if (event.image.uid == img.uid) {
              console.log("same image");
              canDelete = false;
            }
          });
          if (canDelete) {
            promises.push(deletePhoto(img));
          }
        }
        console.log(promises.length);
        return Promise.all(promises);
      });
  };

  const addToGuestList = (data, id, numGuests) => {
    //add user's to the Guestlist
    firestore
      .collection("events")
      .doc(id)
      .update(data)
      .then(() => {
        return firestore
          .collection("venues")
          .doc(venueId)
          .update({
            totalGuests: fieldValue.increment(numGuests),
          });
      })
      .then(() => {
        console.log("Document successfully edited!");
        message.success("Successfully added to the guestlist");
      })
      .catch(function (error) {
        console.error("Error editing document: ", error);
        message.error("Error editing the event. Please try again...");
      });
  };

  const updateGuestlist = (newGuestList, id) => {
    firestore
      .collection("events")
      .doc(id)
      .update({
        guestlist: newGuestList,
      })
      .then(() => {
        console.log("Success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <CreateEventForm handleCreate={handleCreate} deletePhoto />
      </Route>
      <Route
        path={`${path}/details`}
        render={({ location }) => (
          <EventDetails
            location={location}
            addToGuestList={addToGuestList}
            updateGuestlist={updateGuestlist}
          />
        )}
      />
      <Route path={`${path}`}>
        <ActiveEventsList
          venueId={venueId}
          activeEvents={activeEvents}
          pastEvents={pastEvents}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          history={history}
          deleteHelper={deleteHelper}
          deletePhoto={deletePhoto}
        />
      </Route>
    </Switch>
  );
};

export default ManageEvents;
