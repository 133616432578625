import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

const BecomeMember = () => {
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  return (
    <MainContainer>
      <FormContainer id="becomeamember" xs={20} sm={16} md={12} lg={8}>
        <Title>Become a Member</Title>
        <SubTitle>Join our mailing list for the latest updates</SubTitle>

        <div
          id="mc_embed_signup"
          style={{
            width: "100%",
            color: "white",
            backgroundColor: "transparent",
          }}
        >
          <form
            action="https://gmail.us20.list-manage.com/subscribe/post?u=979aab5e75e1b0341f8d9f77e&amp;id=4dd425d0cc"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label
                  htmlFor="mce-EMAIL"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  style={{ color: "black" }}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div
                className="mc-field-group"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                <label htmlFor="mce-FNAME">First Name </label>
                <input
                  style={{ color: "black" }}
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  name="FNAME"
                  className=""
                  id="mce-FNAME"
                />
              </div>
              <div
                className="mc-field-group"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                <label htmlFor="mce-LNAME">Last Name </label>
                <input
                  style={{ color: "black" }}
                  type="text"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  name="LNAME"
                  className=""
                  id="mce-LNAME"
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_979aab5e75e1b0341f8d9f77e_4dd425d0cc"
                  tabIndex="-1"
                  value=""
                  readOnly
                />
              </div>
              <div
                className="clear"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "27px",
                }}
              >
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  readOnly
                  style={{
                    backgroundColor: "#a0905d",
                    height: "40px",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </FormContainer>
    </MainContainer>
  );
};

export default BecomeMember;

const MainContainer = styled(Row)`
  background-color: black;
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;
const FormContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 76px;
`;

const Title = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 34px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 400;
`;
const SubTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  margin-bottom: 60px;
  font-weight: 300;
  text-align: center;
`;

// const Label = styled.p`
// 	font-size: 18px;
// 	color: white;
// 	font-weight: 400;
// 	font-family: 'Roboto', sans-serif;
// `

// const FormButton=styled(Button)`
// 	font-family: 'Roboto', sans-serif;
// 	font-weight: 400;
// 	margin: 20px 10px 20px 10px;
// 	background-color: #162937;
// 	border-color: #162937;
// 	color: white;
// 	padding: inherit;
// 	font-size: 18px;
// 	height: 33px;
// `

// <Label>First Name</Label>
// 	 		<FormInput size='large'/>
// 	 		<Label>Last Name</Label>
// 	 		<FormInput size='large'/>
// 	 		<Label>Email Address</Label>
// 	 		<FormInput size='large'/>
// 	 		<FormButton type='primary' block>Sign Up</FormButton>
