import React from "react";
import styled from "styled-components";

const ContactUs = () => (
  <LandingContentWrapper id="contact-us">
    <TitleWrapper>
      <Title>
        <span>CONNECT WITH US</span>
      </Title>
      <Description>
        If you have any questions feel free to contact us at
        roberto.lentini@tipsisocial.com
      </Description>
    </TitleWrapper>
  </LandingContentWrapper>
);

export default ContactUs;

const LandingContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 100%;
  padding: 64px 24px;
  position: relative;
  margin: 0px auto;
  will-change: transform;
  background: black;
`;

const TitleWrapper = styled.div`
  margin: 100px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.text`
  font-size: 36px;
  color: #a0905d;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
`;

const Description = styled.text`
  font-size: 18px;
  color: #a0905d;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
`;
