import React from "react";
import { Col, Row, Collapse, Form, Button, Input, Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { firebaseAuth, auth } from "../../../firebase";
import { message } from "antd";

const SettingsPage = ({ history }) => {
  console.log(history);
  const [resetPasswordForm] = Form.useForm();
  const [resetEmailForm] = Form.useForm();
  const [deleteAccountForm] = Form.useForm();
  const resetPassword = ({ currentPassword, newPassword, confirmPassword }) => {
    if (!currentPassword) {
      message.error(
        "You must re-authenticate yourself by providing your current password"
      );
      return;
    }
    if (!newPassword || !confirmPassword) {
      message.error("All fields are required in order to reset your password");
      return;
    }
    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match! Please try again");
      return;
    }
    const user = auth.currentUser;
    const credentials = firebaseAuth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return user.updatePassword(newPassword);
      })
      .then(function () {
        message.success("Successfully changed your password");
        resetPasswordForm.resetFields();
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const resetEmail = ({ password, newEmail }) => {
    if (!password) {
      message.error(
        "You must re-authenticate yourself by providing your password"
      );
      return;
    }
    var user = auth.currentUser;
    const credentials = firebaseAuth.EmailAuthProvider.credential(
      user.email,
      password
    );
    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return user.updateEmail(newEmail);
      })
      .then(function () {
        message.success("Successfully changed your email for this account");
        resetEmailForm.resetFields();
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const deleteAccount = ({ password }) => {
    if (!password) {
      message.error(
        "You must re-authenticate yourself by providing your password"
      );
      return;
    }
    var user = auth.currentUser;
    history.push("/");
    const credentials = firebaseAuth.EmailAuthProvider.credential(
      user.email,
      password
    );
    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return user.delete();
      })
      .then(function () {
        message.success("Successfully deleted your account");
        history.push("/");
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <Row style={{ justifyContent: "center" }}>
      <Col xs={24} sm={24} md={16}>
        <h1 style={{ fontSize: "30px", color: "#a0905d" }}>
          <SettingOutlined /> Settings
        </h1>
        <Collapse accordion className="elevated">
          <Collapse.Panel header="Reset Password" key="1">
            <Form
              layout="vertical"
              form={resetPasswordForm}
              onFinish={resetPassword}
            >
              <Form.Item label="Current Password" name="currentPassword">
                <Input.Password className="elevated" />
              </Form.Item>
              <Form.Item label="New Password" name="newPassword">
                <Input.Password className="elevated" />
              </Form.Item>
              <Form.Item label="Confirm Password" name="confirmPassword">
                <Input.Password className="elevated" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Collapse.Panel>
          <Collapse.Panel header="Change Email" key="2">
            <Form layout="vertical" form={resetEmailForm} onFinish={resetEmail}>
              <Form.Item label="Password" name="password">
                <Input.Password className="elevated" />
              </Form.Item>
              <Form.Item label="New Email" name="newEmail">
                <Input className="elevated" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Collapse.Panel>
          <Collapse.Panel header="Delete Account" key="3">
            <Form
              layout="vertical"
              form={deleteAccountForm}
              onFinish={deleteAccount}
            >
              <Form.Item label="Password" name="password">
                <Input.Password className="elevated" />
              </Form.Item>
              <Form.Item>
                <Button type="danger" htmlType="submit">
                  Delete Account
                </Button>
              </Form.Item>
            </Form>
          </Collapse.Panel>
          <Collapse.Panel
            header="Support/Contact Information"
            key="4"
          ></Collapse.Panel>
        </Collapse>
      </Col>
      <Modal></Modal>
    </Row>
  );
};

export default SettingsPage;
