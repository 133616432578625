import React from 'react';
import Layout from 'antd/lib/layout';
import styled from 'styled-components';
import AppFeatures from '../../components/UserLanding/AppFeatures';
import UserNav from '../../components/UserLanding/UNav';
import UBanner from '../../components/UserLanding/UBanner';
import BecomeMember from '../../components/UserLanding/BecomeMember';
import UConnectWithUs from '../../components/UserLanding/UConnectWithUs';
import UFooter from '../../components/UserLanding/UFooter';
import Share from '../../components/UserLanding/Share';
import FeaturesList from '../../components/UserLanding/FeaturesList';
import UserBackgroundImg from '../../assets/img/bckimg.png';

const { Header, Footer, Content } = Layout;

const UserHeader = styled(Header)`
  background-color: black;
  opacity:0.91;
   @media only screen and (max-width: 752px){
    padding-left:20px;
    padding-right:0;
  }
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  padding: 0;
`;

const HeaderSection = styled(Content)`
  justify-content: center;
  background-image: url(${UserBackgroundImg});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

`

const UserLanding = () => (
    <div>
        <Layout className="layout">
            <HeaderSection>
                <UserHeader>
                    <UserNav id="landing-nav" />
                </UserHeader>
                <UBanner id="landing-banner" key="Banner0_1" />
            </HeaderSection>
            <Content>
                <AppFeatures />
            </Content>
            <Content>
                <FeaturesList />
            </Content>
            <Content>
                <BecomeMember />
            </Content>
            <Content>
                <Share />
            </Content>
            <Content>
                <UConnectWithUs />
            </Content>
            <StyledFooter>
                <UFooter id="landing-footer" key="Footer1_0" />
            </StyledFooter>
        </Layout>
    </div>
);
  
export default UserLanding;
  