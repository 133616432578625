import axios from "axios";

const geoCode = async (address, city, province) => {
  const location = `${address} ${city} ${province}`;
  try {
    const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
      params: {
        address: location,
        key: "AIzaSyDd58mny_d_2Lv4-8W4H76y4j9Y0-WT46g",
      },
    });
    console.log(response);

    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject();
  }
};

export default geoCode;
