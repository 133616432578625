import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Layout from "antd/lib/layout";
import PortalMenu from "../../components/Manage/PortalMenu";
import PortalHome from "../../components/Manage/PortalHome";
import SettingsPage from "../../components/Manage/SettingsPage";
import ManageEvents from "../ManageEvents";
import TipsiSocialLogo from "../../assets/img/TIPSISOCIAL.png";
// import UserContext from '../../contexts/UserContext';
import { auth, firestore } from "../../firebase";
import VenueDetails from "../../components/Manage/VenueDetails";
import VenueContext from "../../contexts/VenueContext";
import { message } from "antd";
import moment from "moment";

const { Sider, Content, Footer } = Layout;

const OwnerDashboard = () => {
  const { venue, venueId } = useContext(VenueContext);
  const history = useHistory();
  const { path } = useRouteMatch();
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      history.push("/owners");
    } catch (err) {
      message.error(err.message);
    }
  };
  const sortEvents = (events) => {
    const today = new Date();
    const active_events = [];
    const past_events = [];

    events.forEach((event) => {
      // After 8am of the next day the event will be considered a past event
      const endDate = setEndDate(new Date(moment(event.date).format("MMM DD YYYY")));
      if (endDate < today) {
        past_events.push(event);
      } else {
        active_events.push(event);
      }
    });
    active_events.sort((e1, e2) => {
      return new Date(e1.date) - new Date(e2.date);
    });
    past_events.sort((e1, e2) => {
      return new Date(e2.date) - new Date(e1.date);
    });
    return { active_events, past_events };
  };
  const setEndDate = (date) => {
    //we set the end date of an event to be 8am of the day after
    var result = new Date(date);
    result.setDate(result.getDate() + 1);
    result.setHours(result.getHours() + 8);
    return result;
  };
  const updateVenue = (data) => {
    //update venue profile information
    console.log("DATA", data);
    console.log("VenueId", venueId);
    firestore
      .collection("venues")
      .doc(venueId)
      .update(data)
      .then(() => {
        message.success("Successfully updated your venue profile");
        history.push("/owners/portal");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  return (
    <StyledLayout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        id="sider"
        style={{ backgroundColor: "black", color: "#a0905d" }}
        onCollapse={onCollapse}
      >
        {!collapsed && (
          <NavLogo>
            <Logo src={TipsiSocialLogo} />
          </NavLogo>
        )}
        <PortalMenu signOut={signOut} />
      </Sider>
      <Layout className="site-layout">
        <PortalContent>
          <Switch>
            <Route
              exact
              path={path}
              render={(props) => (
                <PortalHome
                  venue={venue}
                  venueId={venueId}
                  sortEvents={sortEvents}
                  setEndDate={setEndDate}
                  {...props}
                />
              )}
            />
            <Route
              path={`${path}/events`}
              render={(props) => <ManageEvents venueId={venueId} {...props} sortEvents={sortEvents} />}
            />
            <Route path={`${path}/venue`}>
              <VenueDetails venue={venue} updateVenue={updateVenue} venueId={venueId} />
            </Route>
            <Route path={`${path}/settings`}>
              <SettingsPage history={history} />
            </Route>
          </Switch>
        </PortalContent>
        <PortalFooter> TipsiSocial 2020 </PortalFooter>
      </Layout>
    </StyledLayout>
  );
};

export default OwnerDashboard;

const StyledLayout = styled(Layout)`
  background-color: black;
`;
const NavLogo = styled.div`
  width: 80%;
  background: transparent;
  color: white;
  float: left;
  line-height: 64px;
  border: none;
  font-weight: 500;
  margin-left: 24px;
`;

const PortalContent = styled(Content)`
  background-color: #ededed;
  padding: 30px;
  color: white;
`;

const PortalFooter = styled(Footer)`
  background-color: #313133;
  text-align: center;
  border-top: 1px solid #272727;
  color: white;
`;

const Logo = styled.img`
  width: 100%;
  height: 30px;
`;
