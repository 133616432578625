import React from 'react';
import styled from 'styled-components';
import BackgroundImg from '../../../assets/img/FinalHowitWorksBck.png';
import Ilusration from '../../../assets/img/undraw_having_fun_iais.png';

const HowItWorks = () => (
  <Container>
      <TitleWrapper>
        <Title>
          How it works
        </Title>
      </TitleWrapper>
    <LandingContentWrapper id="Howitworks">
      <Infoblocks>

        <InfoBlock1>
          <FlexContainer>
            <NumberContainer>
              <One>
                1
              </One>
            </NumberContainer>
              <BlockSubContainer>
              <BlockTitle>
                Publish Bar Description
              </BlockTitle>
              <BlockText>
                Fill out information to attract new clients to your venue.
              </BlockText>
            </BlockSubContainer>
          </FlexContainer>
        </InfoBlock1>

    
        <InfoBlock2>
          <FlexContainer>
            <NumberContainer>
              <Two>
                2
              </Two>
            </NumberContainer>
              <BlockSubContainer>
              <BlockTitle>
                Create Event
              </BlockTitle>
              <BlockText>
                Fill out information regarding your upcoming event.
              </BlockText>
            </BlockSubContainer>
          </FlexContainer>
        </InfoBlock2>

        <InfoBlock3>
          <FlexContainer>
            <NumberContainer>
              <Three>
                3
              </Three>
            </NumberContainer>
              <BlockSubContainer>
              <BlockTitle>
                Manage The Guestlist
              </BlockTitle>
              <BlockText>
                Monitor client arrival and the status of your guestlist.  
              </BlockText>
            </BlockSubContainer>
          </FlexContainer>
        </InfoBlock3>

        <Illus alt="" src={Ilusration} />
      </Infoblocks> 

    </LandingContentWrapper>
  </Container>
);

export default HowItWorks;

const Container = styled.div`
  background-color: black;
`

const LandingContentWrapper = styled.div`
  min-height: 700px;
  position: relative;
  margin:0px auto;
  will-change: transform;
  background-image: url(${BackgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;

  @media only screen and (max-width: 1080px){
    background-color: black;
    background-image: url();
    min-height:800px;
    margin-top:80px;
  }

  @media only screen and (max-width: 1400px){
    min-height:850px;

  }
   @media only screen and (min-width: 1400px){
    min-height:900px;
    margin-top:50px;
    
  }
`;

const Title = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 44px;
  color: white;
  font-weight: 300;
  margin: 0px auto;

  @media only screen and (max-width: 1080px){
    margin: 0px;
  }
  
`;

const TitleWrapper = styled.div`
  margin: 0px;
  text-align: center;
  background-color: black;
  padding-top: 100px;

  @media only screen and (max-width: 1080px){
    margin: 0px auto;
  }
  @media only screen and (min-width: 1600px){
    padding-bottom: 50px;
  }
`;

const BlockTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  line-height: 32px;
  margin: 10px auto;
  color: white;
  padding: 0;
  padding-top: 30px;
  font-weight: 300;
  text-align: left;
`;


const Infoblocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1000px;
  margin:0px auto;
  padding-top:75px;
  @media only screen and (max-width: 1080px){
    flex-direction: column;
    background-color: #A62639;
    width: 28.983vw; /*69.9834vw*/
    height: 1080px;
    justify-content: space-between
    }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const NumberContainer = styled.div``

const BlockSubContainer = styled.div``


const One = styled.div`
  width: 120px;
  color: white;
  font-size: 235px;
  font-family: Segoe UI;
  text-align: center;
  line-height: 203px;

  @media only screen and (max-width: 1080px){
    line-height: 227px;
  }
`
const Two = styled.div`
  width: 120px;
  color: white;
  font-size: 235px;
  font-family: Segoe UI;
  text-align: center;
  line-height: 171px;
  @media only screen and (max-width: 1080px){
    line-height: 227px;
  }

  `

  const Three = styled.div`
  width: 120px;
  color: white;
  font-size: 235px;
  font-family: Segoe UI;
  text-align: center;
  line-height: 205px;

  @media only screen and (max-width: 1080px){
    line-height: 220px;
  }
  `
  
const BlockText = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: white;
  text-align: left;
`

const InfoBlock1 = styled.div`
  width: 368px;
  height: 150px;
  @media only screen and (max-width: 1080px){
    margin-top: 0px;
    align-self: center;
  }
  @media only screen and (max-width: 391px){
    width: 90vw
  }
`
const InfoBlock2 = styled.div`
  width: 368px;
  height: 150px;
  margin-top: 170px;

  @media only screen and (max-width: 1080px){
    margin-top: 0px;
    align-self: center;
  }
  @media only screen and (max-width: 391px){
    width: 90vw
  }
`
const InfoBlock3 = styled.div`
  width: 368px;
  height: 150px;
  margin-top: 350px;

  @media only screen and (max-width: 1080px){
    margin-top: 0px;
    align-self: center;
  }
  @media only screen and (max-width: 391px){
    width: 90vw
  }
`
const Illus = styled.img`
  height: 0;
  width: 0;

  @media only screen and (max-width: 1080px){
    height: 240px;
    width: 384px;
    align-self: center;
    margin-top: 100px;
  }
  @media only screen and (max-width: 391px){
    width: 90vw
  }
`
