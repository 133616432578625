import React from "react";
import Menu from "antd/lib/menu";
import styled from "styled-components";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import {
  SettingOutlined,
  HomeOutlined,
  CalendarOutlined,
  LogoutOutlined,
  EditOutlined,
} from "@ant-design/icons";

const PortalMenu = (props) => {
  let { url } = useRouteMatch();
  let location = useLocation();
  return (
    <Menu
      style={{ backgroundColor: "black", color: "#a0905d" }}
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      defaultOpenKeys={[`${url}/events`]}
    >
      <MenuItem
        className="menuitem"
        key={url}
        icon={<HomeOutlined style={{ color: "#a0905d" }} />}
      >
        <MenuLink style={{ color: "#a0905d" }} to={url}>
          Dashboard
        </MenuLink>
      </MenuItem>
      <MenuItem
        className="menuitem"
        key={`${url}/events`}
        icon={<CalendarOutlined style={{ color: "#a0905d" }} />}
      >
        <Link style={{ color: "#a0905d" }} to={`${url}/events`}>
          Manage Events
        </Link>
      </MenuItem>
      <MenuItem
        className="menuitem"
        key={`${url}/venue`}
        icon={<EditOutlined style={{ color: "#a0905d" }} />}
      >
        <Link style={{ color: "#a0905d" }} to={`${url}/venue`}>
          Venue Profile
        </Link>
      </MenuItem>
      <MenuItem
        className="menuitem"
        key={`${url}/settings`}
        icon={<SettingOutlined style={{ color: "#a0905d" }} />}
      >
        <Link style={{ color: "#a0905d" }} to={`${url}/settings`}>
          Settings
        </Link>
      </MenuItem>
      <MenuItem
        className="menuitem"
        key={`${url}/signout`}
        icon={<LogoutOutlined style={{ color: "#a0905d" }} />}
        onClick={props.signOut}
      >
        <span style={{ color: "#a0905d" }}>Sign Out</span>
      </MenuItem>
    </Menu>
  );
};

export default PortalMenu;

const MenuItem = styled(Menu.Item)`
  background-color: black;
  &:active,
  &:focus {
    background-color: #a0905d;
    color: white;
  }
`;

const MenuLink = styled(Link)``;
