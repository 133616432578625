import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import screenshotsrc from "../../../assets/img/LoadingScreen.png";

const FeaturesList = () => (
  <MainContainer>
    <Col xs={24} sm={12} lg={8}>
      <PhoneRow>
        <PhoneContainer>
          <ScreenShot alt="" src={screenshotsrc} />
        </PhoneContainer>
      </PhoneRow>
    </Col>
    <Col xs={24} sm={12} lg={12}>
      <InfoContainer>
        <FeaturesContainer xs={24} md={24} lg={24}>
          <Title>Become A Member At No Cost</Title>
          <FeatureRow>
            <Col xs={24} lg={10}>
              <FeatureColumn>
                <Feature>
                  <Icon className="fas fa-check"></Icon> Launch party invitation
                </Feature>
                <Feature>
                  <Icon className="fas fa-check"></Icon> Early access to app
                </Feature>
              </FeatureColumn>
            </Col>
            <Col xs={24} lg={10}>
              <FeatureColumn>
                <Feature>
                  <Icon className="fas fa-check"></Icon> App access code
                </Feature>
                <Feature>
                  <Icon className="fas fa-check"></Icon> Updates and information
                </Feature>
              </FeatureColumn>
            </Col>
          </FeatureRow>
        </FeaturesContainer>
      </InfoContainer>
    </Col>
  </MainContainer>
);

export default FeaturesList;

const MainContainer = styled(Row)`
  background-color: black;
  width: 100%;
  min-height: 600px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 980px) {
    padding-left: 25px;
    padding-right: 144px;
  }
  @media only screen and (max-width: 890px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
`;
const PhoneRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 890px) {
    align-self: center;
  }
`;
const PhoneContainer = styled(Col)`
  width: 270px;
  padding-left: 20px;
  padding-top: 50px;
  @media only screen and (max-width: 890px) {
    padding-left: 0px;
  }
`;

const InfoContainer = styled(Row)`
	margin-top:20px;
	height:100%;
  	
	@media only screen and (max-width: 980px){
    	margin-top: -57px;
  	}

 	@media only screen and (max-width: 890px){
    	margin-top: 20px;
    	justify-content-center
  	}
`;
const Title = styled.h3`
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 36px;
  font-weight: 300;

  margin-left: 10px;
  margin-right: 10px;
  @media only screen and (max-width: 890px) {
    margin-top: 60px;
    text-align: center;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 40px;
    font-size: 30px;
    text-align: center;
  }
`;
const ScreenShot = styled.img`
  height: 560px;
  width: 270px;
  margin-top: -31px;
`;

const FeatureColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 115px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;

  @media only screen and (max-width: 890px) {
    text-align: center;
  }
`;

const Feature = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 400;
`;
const FeatureRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  max-width: 500px;
`;

const FeaturesContainer = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 60px;

  @media only screen and (max-width: 890px) {
    margin-left: 0px;
    align-items: center;
  }
`;

const Icon = styled.i`
  color: white;
  font-size: 16px;
  margin-right: 10px;
`;
