import React from 'react';
import Layout from 'antd/lib/layout';
import styled from 'styled-components';
import OwnerNav from '../../components/OwnerLanding/Nav';
import Banner from '../../components/OwnerLanding/Banner';
import WhyTipsi from '../../components/OwnerLanding/WhyTipsi';
import OwnerFooter from '../../components/OwnerLanding/Footer';
import ContactUs from '../../components/OwnerLanding/ContactUs';
import HowItWorks from '../../components/OwnerLanding/HowItWorks';
import Registration from '../../components/OwnerLanding/Registration';
import backgroundImg2 from '../../assets/img/Web192010.png';

 
const {Header, Footer, Content } = Layout;

const OwnerHeader = styled(Header)`
  background-color: black;
  opacity:0.94;
  @media only screen and (max-width: 752px){
    padding-left:20px;
    padding-right:0;
  }
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  padding: 0;
`;

const HeaderSection = styled(Content)`
  justify-content: center;
  background-image: url(${backgroundImg2});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  @media only screen and (max-width: 752px){
    background-color: black;
    background-image: url(${backgroundImg2});
  }
`

const Landing = () => (
  <div>
    <Layout className="layout">
      <HeaderSection>
        <OwnerHeader>
        <OwnerNav id="landing-nav" />
      </OwnerHeader>
        <Banner id="landing-banner" key="Banner0_1" />
        <WhyTipsi />
      </HeaderSection>
      <Content>
        <HowItWorks />
      </Content>
      <Content>
       <Registration />
      </Content>
      <Content>
        <ContactUs />
      </Content>
      <StyledFooter>
        <OwnerFooter id="landing-footer" key="Footer1_0" />
      </StyledFooter>
    </Layout>
  </div>
);

export default Landing;
